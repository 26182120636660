import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import * as url from "../url_helper";

import {
  calenderDefaultCategories,
  events,
  orders,
  productsData,
  customerData,
  shops,
  cartData,
  comments,
  chats,
  groups,
  messages,
  contacts
} from "../../common/data";

let users = [
  // { id: 1, username: 'admin', email: 'admin@themesdesign.in' }
];

const fakeBackend = () => {
  // This sets the mock adapter on the default instance
  var mock = new MockAdapter(axios);



  mock.onPost('/post-login').reply(function (config) {
    const user = JSON.parse(config['data']);
    // console.log(user)
    
    // const validUser = users.filter(usr => usr.email === user.username );
    // console.log('1')
    // console.log(validUser)

    return new Promise(function (resolve, reject) {
      // setTimeout(function () {
        // if (validUser[0].length != 1) {
          resolve([200, user]);
        // } else {
        //   reject([400, "Username and password are invalid. Please enter correct username and password"]);
        // }
      // });
    });
  });

  // mock.onPost('/forget-pwd').reply(function (config) {
  //   // User needs to check that user is eXist or not and send mail for Reset New password

  //   return new Promise(function (resolve, reject) {
  //     setTimeout(function () {
  //       resolve([200, "Check you mail and reset your password."]);
  //     });
  //   });

  // });
  // mock.onGet(url.GET_EVENTS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (events) {
  //         // Passing fake JSON data as response
  //         resolve([200, events])
  //       } else {
  //         reject([400, "Cannot get events"])
  //       }
  //     })
  //   })
  // })

  // mock.onPost(url.ADD_NEW_EVENT).reply(event => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (event && event.data) {
  //         // Passing fake JSON data as response
  //         resolve([200, event.data])
  //       } else {
  //         reject([400, "Cannot add event"])
  //       }
  //     })
  //   })
  // });

  // mock.onPut(url.UPDATE_EVENT).reply(event => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (event && event.data) {
  //         // Passing fake JSON data as response
  //         resolve([200, event.data])
  //       } else {
  //         reject([400, "Cannot update event"])
  //       }
  //     })
  //   })
  // })

  // mock.onDelete(url.DELETE_EVENT).reply(config => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (config && config.headers) {
  //         // Passing fake JSON data as response
  //         resolve([200, config.headers.event])
  //       } else {
  //         reject([400, "Cannot delete event"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_CATEGORIES).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (calenderDefaultCategories) {
  //         // Passing fake JSON data as response
  //         resolve([200, calenderDefaultCategories])
  //       } else {
  //         reject([400, "Cannot get categories"])
  //       }
  //     })
  //   })
  // });


  // mock.onGet(url.GET_PRODUCTS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (productsData) {
  //         // Passing fake JSON data as response
  //         resolve([200, productsData])
  //       } else {
  //         reject([400, "Cannot get products"])
  //       }
  //     })
  //   })
  // });

  // mock.onGet(new RegExp(`${url.GET_PRODUCTS_DETAIL}/*`)).reply(config => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (productsData) {
  //         // Passing fake JSON data as response
  //         const { params } = config
  //         const product = productsData.find(
  //           product => product.id.toString() === params.id.toString()
  //         )
  //         resolve([200, { ...product, comments }])
  //       } else {
  //         reject([400, "Cannot get product detail"])
  //       }
  //     })
  //   })
  // });

  // mock.onGet(url.GET_ORDERS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (orders) {
  //         // Passing fake JSON data as response
  //         resolve([200, orders])
  //       } else {
  //         reject([400, "Cannot get orders"])
  //       }
  //     })
  //   })
  // })

  //   mock.onGet(url.GET_CART_DATA).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (cartData) {
  //         // Passing fake JSON data as response
  //         resolve([200, cartData])
  //       } else {
  //         reject([400, "Cannot get cart data"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_CUSTOMERS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (customerData) {
  //         // Passing fake JSON data as response
  //         resolve([200, customerData])
  //       } else {
  //         reject([400, "Cannot get customers data"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_SHOPS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (shops) {
  //         // Passing fake JSON data as response
  //         resolve([200, shops])
  //       } else {
  //         reject([400, "Cannot get shops data"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_CHATS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (chats) {
  //         // Passing fake JSON data as response
  //         resolve([200, chats])
  //       } else {
  //         reject([400, "Cannot get chats"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_GROUPS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (groups) {
  //         // Passing fake JSON data as response
  //         resolve([200, groups])
  //       } else {
  //         reject([400, "Cannot get groups"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(url.GET_CONTACTS).reply(() => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (contacts) {
  //         // Passing fake JSON data as response
  //         resolve([200, contacts])
  //       } else {
  //         reject([400, "Cannot get contacts"])
  //       }
  //     })
  //   })
  // })

  // mock.onGet(new RegExp(`${url.GET_MESSAGES}/*`)).reply(config => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (messages) {
  //         // Passing fake JSON data as response
  //         const { params } = config
  //         const filteredMessages = messages.filter(
  //           msg => msg.roomId === params.roomId
  //         )
  //         resolve([200, filteredMessages])
  //       } else {
  //         reject([400, "Cannot get messages"])
  //       }
  //     })
  //   })
  // })

  // mock.onPost(url.ADD_MESSAGE).reply(config => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (config.data) {
  //         // Passing fake JSON data as response
  //         resolve([200, config.data])
  //       } else {
  //         reject([400, "Cannot add message"])
  //       }
  //     })
  //   })
  // })

}

export default fakeBackend;