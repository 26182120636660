import React, { Component, useEffect, useState, memo } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import SalesAnalytics from "./SalesAnalytics";
import Sources from "./Sources";
import { useHistory } from 'react-router-dom';


function Dashboard(props) {

    const breadcrumbItems = [
    ];
    const history = useHistory();

    const [reports, setReports] = useState([
        { icon: "ri-stack-line", title: "รถทั่งหมด", value: "0" },
        { icon: "ri-store-2-line", title: "รออนุมัติ", value: "0" },
        { icon: "ri-briefcase-4-line", title: "ขายแล้ว", value: "0" },
    ])
    const idDealer = localStorage.getItem("idDealer");

    useEffect(() => {
        fetch(process.env.REACT_APP_URL_API + 'cars/dealer/total-car?dealerId=' + idDealer, {
            method: 'GET',
            headers: {
                'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                'Content-Type': process.env.REACT_APP_Content_Type,
                'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
            }
        })
            .then(response => response.json())
            .then(async (res) => {
                setReports([
                    { icon: "ri-stack-line", title: "รถทั่งหมด", value: res.data.carAll },
                    { icon: "ri-store-2-line", title: "รออนุมัติ", value: res.data.carWaiting },
                    { icon: "ri-briefcase-4-line", title: "ขายแล้ว", value: res.data.carSold }])
                // console.log(res.data)
            })
            .catch((error) => {
                history.push('/404');
            });



    }, []);


    // console.log(process.env)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col xl={12}>
                            <Row>
                                <MiniWidgets reports={reports} />
                            </Row>
                        </Col>

                        <Col xl={8}>
                            <SalesAnalytics />
                        </Col>
                        <Col xl={4}>
                            <Sources />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default memo(Dashboard);
