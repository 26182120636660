import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, min, size } from "lodash";
import { Button, Card, CardBody, Col, Container, Row, ButtonToolbar, ButtonGroup, Modal, ModalHeader, ModalBody, Input, ModalFooter } from "reactstrap";

import * as moment from 'moment';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom";

function ListCar(props) {
  const [carData, setCarData] = useState([]);
  const [totalCar, setTotalCar] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [base, setBase] = useState("");
  const idDealer = localStorage.getItem("idDealer");
  const [startModelDeleteCar, setStartModelDeleteCar] = useState({});

  const [titlecar, setTitleCar] = useState();
  const [idDeleteCar, setIdDeleteCar] = useState();

  useEffect(async () => {
    const data = await fetch(process.env.REACT_APP_URL_API + 'cars/dealer?limit=10&start=0&dealerId=' + idDealer, {
      method: 'GET',
      headers: {
        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Length': '<calculated when request is sent>'
      }
    })
      .then(response => response.json())
      .then(async (res) => {
        if (res.statusCode === 200) {

          setCarData(res.data.results)
          setTotalCar(res.data.length)
          setBase(res.data._links.base)
          if (res.data._links.next !== null) {
            setNextPage(res.data._links.next)

          } else {
            setNextPage(null)
          }
          if (res.data._links.prev !== null && res.data._links.prev) {
            setPrevPage(res.data._links.prev)
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(async () => {
    if ((carData.length > 0)) {

      let status = "รออนุมัติ";
      let createDateTime;
      for (let i = 0; i < carData.length; i++) {
        if (carData[i].approvalDateTime != null) {
          status = "อนุมัติแล้ว";
        }
        if (carData[i].soldDateTime != null) {
          status = "ขายแล้ว";
        }
        let d = new Date(carData[i].createDateTime);
        let dateString = moment(d).format('YYYY-MM-DD');
        createDateTime = dateString
        carData[i].status = status
        carData[i].createDateTime = createDateTime
      }

    }
  }, [carData]);

  const onSubmitPagination = async (e, base, page) => {
    const data = await fetch(base + page, {
      method: 'GET',
      headers: {
        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
        'Content-Type': process.env.REACT_APP_Content_Type,
        'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
      }
    })
      .then(response => response.json())
      .then(async (res) => {
        let status = "รออนุมัติ";
        let createDateTime;
        setCarData(res.data.results)
        setTotalCar(res.data.length)
        setBase(res.data._links.base)
        if (res.data._links.next !== null) {
          setNextPage(res.data._links.next)
        }
        if (res.data._links.prev !== null) {
          setPrevPage(res.data._links.prev)
        }
        for (let i = 0; i < res.data.results.length; i++) {
          if (carData[i].approvalDateTime != null) {
            status = "อนุมัติแล้ว";
          }
          if (carData[i].soldDateTime != null) {
            status = "ขายแล้ว";
          }
          let d = new Date(carData[i].createDateTime);
          let dateString = moment(d).format('YYYY-MM-DD');
          createDateTime = dateString
          carData[i].status = status
          carData[i].createDateTime = createDateTime
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const [breadcrumbItems, setBreadcrumbItems] = useState([
  ]);


  const pageOptions = {
    sizePerPage: 5,
    totalSize: totalCar,
    custom: true,
  }
  const history = useHistory();
  const onSubmitEditCar = (e) => {
    localStorage.setItem('dataObject', JSON.stringify(e));
    let link = '/edit-car?carId=' + e.carId;
    history.push(link);
  }
  const onSubmitDeleteCar = (e) => {
    setTitleCar(e.title)
    setIdDeleteCar(e.carId)
    setStartModelDeleteCar(prevState => ({
      modal_center: !prevState.modal_center
    }));
    removeBodyCss();
  }

  const selectRow = {
    mode: 'checkbox'
  };
  const toggleModel = (e) => {
    setStartModelDeleteCar(prevState => ({
      modal_center: !prevState.modal_center
    }));
    removeBodyCss();

  }
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }
  const onConfrimDeleteCar = () => {
    const dataBody = {
      carIdList: [idDeleteCar]
    };
    fetch(process.env.REACT_APP_URL_API + 'cars/list', {
      method: 'DELETE', // or 'PUT'
      headers: {
        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.statusCode === 200) {
          toggleModel()
          setCarData(carData.filter(({ carId }) => carId !== idDeleteCar))
        }
      }
      );

  }
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="รายการรถทั้งหมด" breadcrumbItems={breadcrumbItems} />
          <Card>
            <CardBody>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">ชื่อ</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">สถานะ</th>
                    <th scope="col">วันที่ลงขาย</th>
                    <th scope="col">แก้ไข</th>
                  </tr>
                </thead>
                <tbody>
                  {carData.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <img className="img-thumbnail" alt="Nazox" width="200" src={item.thumbnailImage} />
                      </th>
                      <td>{item.title}</td>
                      <td>{formatNumber(item.price)}</td>
                      <td>{item.status}</td>
                      <td>{item.createDateTime}</td>
                      <td>
                        <Button color="link" className="waves-effect me-1" onClick={() => onSubmitEditCar(item)} >
                          <i className="mdi mdi-pencil font-size-18"></i>
                        </Button>
                        <Button color="link" className="waves-effect me-1" style={{ color: "red" }} onClick={() => onSubmitDeleteCar(item)} >
                          <i className="mdi mdi-trash-can font-size-18"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination pagination-rounded justify-content-end mb-2">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li className={prevPage === null ? "page-item disabled" : "page-item"}>
                      <a class="page-link" onClick={(e) => onSubmitPagination(e, base, prevPage)} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className={nextPage == null ? "page-item disabled" : "page-item"}>
                      <a class="page-link" onClick={(e) => onSubmitPagination(e, base, nextPage)} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </CardBody>
            <Modal
              isOpen={startModelDeleteCar.modal_center}
              toggle={toggleModel}
              centered={true}
            >
              <ModalHeader toggle={() => setStartModelDeleteCar({ modal_static: false })} >
                ลบรถ!
              </ModalHeader>
              <ModalBody>
                <form>
                  <div className="form-group">
                    <label htmlFor="register-code-2">
                      {titlecar}
                    </label>
                  </div>

                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggleModel}
                  color="danger"
                  outline
                  className="waves-effect waves-light me-1"
                >
                  ยกเลิก
                </Button>

                <Button
                  color="secondary"
                  outline
                  type="submit"
                  onClick={onConfrimDeleteCar}
                  className="waves-effect">
                  ยินยัน
                </Button>

              </ModalFooter>
            </Modal>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  )
}

ListCar.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func
}
export default ListCar