import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

function SalesAnalytics(props) {
    const [series, setSeries] = useState([0, 0, 0]);
    const idDealer = localStorage.getItem("idDealer");
    useEffect(() => {
        fetch(process.env.REACT_APP_URL_API + 'cars/dealer/total-car?dealerId=' + idDealer, {
            method: 'GET',
            headers: {
                'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                'Content-Type': process.env.REACT_APP_Content_Type,
                'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
            }
        })
            .then(response => response.json())
            .then((res) => {
                setSeries([res.data.carAll, res.data.carWaiting, res.data.carSold])
                // setState({series:[4,6,7]})
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">ภาพรวมรถของเต็นท์</h4>
                    <div id="donut-chart" className="apex-charts">
                        <ReactApexChart options={{
                            labels: ["รถทั้งหมด", "รออนมุติ", "ขายแล้ว"],
                            plotOptions: {
                                pie: {
                                    donut: {
                                        size: '75%'
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                show: false,
                            },
                            colors: ['#5664d2', '#1cbb8c', '#eeb902'],
                        }} series={series} type="donut" height="250" />
                    </div>
                    <Row>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> รถทั้งหมด</p>
                                <h5>{series[0]}</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> รออนมุติ</p>
                                <h5>{series[1]}</h5>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="text-center mt-4">
                                <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i> ขายแล้ว</p>
                                <h5>{series[2]}</h5>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );

}

export default SalesAnalytics;