import React, { useState, useEffect } from "react";
import { Row, Col, Button, Label, Input, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import S3 from 'react-aws-s3';
import { withRouter, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Select from "react-select";
import ReactDOM from "react-dom";
import { Auth } from "aws-amplify";
import PhoneInput, { format, normalize } from "react-phone-input-auto-format";

// availity-reactstrap-validation
// actionxw
// Redux
import cardId from "../../assets/images/dealer/id-card.png";
import bookCard from "../../assets/images/dealer/book-card.png";
// import { v4 as uuid } from "uuid";


function Register(props) {
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [lineId, setLineId] = useState("");
    const [taxIdentificationNumber, setTaxIdentificationNumber] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [companyAddress, setCompanyAddress] = useState("");
    const [facebook, setFacebook] = useState("");
    const [website, setWebSite] = useState("");

    const [fileIdCard, setFileIdCard] = useState({ file: cardId });
    const [fileBookCard, setFileBookCard] = useState({ file: bookCard });

    const [fileIdCard1, setFileIdCard1] = useState("");
    const [fileBookCard1, setFileBookCard1] = useState("");
    const [stateModel, setStateModel] = useState({});
    const [uuId, setUuId] = useState("");
    const [code, setCode] = useState("");
    const [waitingForCode, setWaitingForCode] = useState(false);

    const history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const emailDealer = await fetch(process.env.REACT_APP_URL_API + 'dealer/check-user/' + email, {
                method: 'GET',
                headers: {
                    'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                    'Content-Type': process.env.REACT_APP_Content_Type,
                    'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
                }
            })
                .then(response => response.json())
                .then(async (res) => {
                    if (res.statusCode === 200) {
                        const userSigUp = await Auth.signUp({
                            username: email,
                            password: password,
                            attributes: {
                                email: email,
                                phone_number: phoneNumber
                            }
                        })
                            .then(async (user) => {
                                setUuId(user.userSub)

                                let S3Config = {
                                    bucketName: "s3-motto-content",
                                    region: process.env.REACT_APP_Region,
                                    accessKeyId: process.env.REACT_APP_S3_AccessKeyId,
                                    secretAccessKey: process.env.REACT_APP_S3_SecretAccessKey,
                                    s3Url: process.env.REACT_APP_S3Url,
                                };

                                let cardIdLocation = "";
                                let certificate = "";
                                S3Config.dirName = "test-s3/dealer/image-card-id";
                                const ReactS3Client = new S3(S3Config);
                                await ReactS3Client
                                    .uploadFile(fileIdCard1, uuId)
                                    .then((res) => {
                                        cardIdLocation = res.location;
                                    }).catch((err) => cardIdLocation = "");

                                S3Config.dirName = "test-s3/dealer/certificate";
                                const ReactS3Client2 = new S3(S3Config);
                                await ReactS3Client2
                                    .uploadFile(fileIdCard1, uuId)
                                    .then((res) => {
                                        certificate = res.location;
                                    }).catch((err) => certificate = "");
                                const dataBody = {
                                    uuId: user.userSub,
                                    userName: userName,
                                    email: email,
                                    lineId: lineId,
                                    taxIdentificationNumber: parseInt(taxIdentificationNumber),
                                    companyName: companyName,
                                    phoneNumber: parseInt(phoneNumber),
                                    companyAddress: companyAddress,
                                    facebook: facebook,
                                    imageCardId: cardIdLocation,
                                    imageBook: certificate,
                                    website: website,
                                };
                                fetch(process.env.REACT_APP_URL_API + 'dealer/register', {
                                    method: 'POST', // or 'PUT'
                                    headers: {
                                        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                                        'Content-Type': process.env.REACT_APP_Content_Type,
                                        'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
                                    },
                                    body: new URLSearchParams(dataBody)
                                }).then(response => response.json())
                                    .then(response => {
                                        setStateModel(prevState => ({
                                            modal_center: !prevState.modal_center
                                        }));
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                    });
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                            });
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        } catch (error) {

        }
    }
    const onPhoneChange = (phoneNumber) => {

        const normalized = normalize(phoneNumber);
        const regex = /^0/;

        if (normalized.match(regex)) {
            phoneNumber = normalized.replace(regex, '+66');
        } else {
            phoneNumber = normalized.replace(/^/, '+66');
        }

        // console.log('set:' + phoneNumber);
        setPhoneNumber(phoneNumber);
    };

    const tog_center = () => {
        setStateModel(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();

    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };
    const handleUserName = (e) => {
        setUserName(e.target.value);
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleChangeIdCard = (e) => {
        setFileIdCard({
            file: URL.createObjectURL(e.target.files[0])

        })
        setFileIdCard1(e.target.files[0])
    }
    const handleChangeBookCard = (e) => {
        setFileBookCard({
            file: URL.createObjectURL(e.target.files[0])
        })
        setFileBookCard1(e.target.files[0])
    }
    const handlePasswordConfirm = (e) => {
        setPasswordConfirm(e.target.value)

    }
    const handleLineId = (e) => {
        setLineId(e.target.value)
    }
    const handleTaxIdentificationNumber = (e) => {
        setTaxIdentificationNumber(e.target.value)
    }
    const handleCompanyName = (e) => {
        setCompanyName(e.target.value)
    }
    const handleAddressCompanyName = (e) => {
        setCompanyAddress(e.target.value)
    }
    const handleFacebook = (e) => {
        setFacebook(e.target.value)

    }
    const handleWebSite = (e) => {
        setWebSite(e.target.value)
    }

    const confirmSignUp = (e) => {
        e.preventDefault();
        Auth.confirmSignUp(email, code)
            .then((data) => {
                history.push('/dealerplatform/login');
            })
            .catch((err) => console.log(err));
    };
    const resendCode = () => {
        Auth.resendSignUp(email)
            .then(() => {

            })
            .catch((e) => {

            });
    };
    return (
        <React.Fragment>
            <Container fluid className="container-fluid-register bg-overlay-register">
                <Card className="card-register card-overlay-register ">
                    <CardBody>
                        <h1 className="">ลงทะเบียน</h1>


                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="useremail">ชื่อ  *</Label>
                                        <input
                                            onChange={handleUserName}
                                            // name="username"
                                            value={userName}
                                            type="text"
                                            className="form-control"
                                            required
                                            placeholder="กรอกชื่อ นามสกุล" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="basicpill-lastname-input15">อีเมลล์ *</Label>
                                        <input
                                            name="email"
                                            onChange={handleEmail}
                                            value={email}
                                            required
                                            type="email" className="form-control"
                                            placeholder="กรอกอีเมลล์" />
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">รหัสผ่าน *</Label>
                                        <Input
                                            onChange={handlePassword}
                                            value={password}
                                            type="password"
                                            className="form-control"
                                            required
                                            placeholder="กรอกรหัสผ่าน" />
                                    </div>
                                </Col>
                                {/* <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">ยินยันรหัสผ่าน *</Label>
                                        <Input
                                            onChange={handlePasswordConfirm}
                                            value={passwordConfirm}
                                            type="password"
                                            className="form-control"
                                            placeholder="ยืนยันรหัสผ่าน" />
                                    </div>
                                </Col> */}
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">ไลน์ไอดี *</Label>
                                        <Input
                                            onChange={handleLineId}
                                            value={lineId}
                                            type="text"
                                            required
                                            className="form-control"
                                            placeholder="กรอกไลน์ไอดี" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">เลขประจำตัวผู้เสียภาษี *</Label>
                                        <Input
                                            onChange={handleTaxIdentificationNumber}
                                            value={taxIdentificationNumber}
                                            type="number"
                                            required
                                            className="form-control"
                                            placeholder="กรอกไลน์ไอดี" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">ชื่อ บริษัท / ร้านค้า *</Label>
                                        <Input
                                            onChange={handleCompanyName}
                                            value={companyName}
                                            type="text"
                                            required
                                            className="form-control"
                                            placeholder="กรอกไลน์ไอดี" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">เบอร์โทร *</Label>
                                        <PhoneInput
                                            className="form-control"
                                            onChange={onPhoneChange}
                                            placeholder="กรอกเบอร์โทร"
                                            required
                                            ame="register-phone"
                                            autoComplete="off" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">ที่อยู่บริษัท / ร้านค้า *</Label>
                                        <Input
                                            onChange={handleAddressCompanyName}
                                            value={companyAddress}
                                            type="text"
                                            className="form-control"
                                            required
                                            placeholder="กรอกที่อยู่บริษัท / ร้านค้า" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">facebook</Label>
                                        <Input
                                            onChange={handleFacebook}
                                            value={facebook}
                                            type="text"
                                            className="form-control"

                                            placeholder="กรอก facebook" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="mb-3">
                                        <Label htmlFor="userpassword">เว็ปไชต์
                                        </Label>
                                        <Input
                                            onChange={handleWebSite}
                                            value={website}
                                            type="text"
                                            className="form-control"

                                            placeholder="เว็ปไชต์" />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    {/* <Label htmlFor="userpassword">อัพโหลดเอกสารสำคัญ *
                                    </Label> */}
                                    <Row>
                                        <Col lg="6">
                                            <Label htmlFor="userpassword">อัพโหลดบัตรประชาชน *
                                            </Label>
                                            <div className="mb-3 ">
                                                <div className="image-card-1">
                                                    <img src={fileIdCard.file} />
                                                </div>
                                                <div className="">
                                                    <input
                                                        required
                                                        type="file"

                                                        onChange={handleChangeIdCard} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <Label htmlFor="userpassword">หนังสือรับรอง *
                                            </Label>
                                            <div className="mb-3 ">
                                                <div className="image-card-1">
                                                    <img src={fileBookCard.file} />
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="file"
                                                        required
                                                        onChange={handleChangeBookCard} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                                <div className="bt-register">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        className=" waves-effect waves-light me-1"
                                    // onClick={handleSubmit}
                                    >
                                        ลงทะเบียน
                                    </Button>
                                </div>
                            </Row>
                        </form>

                        <Modal
                            isOpen={stateModel.modal_center}
                            toggle={tog_center}
                            centered={true}
                        >
                            <ModalHeader toggle={() => setStateModel({ modal_standard: false })}>
                                ยืนยันตัวตน
                            </ModalHeader>
                            <ModalBody>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="register-code-2">
                                            รหัสยืนยันถูกส่งไปยังอีเมลที่คุณทำการลงทะเบียน *
                                        </label>
                                        <input type="text" onChange={(e) => setCode(e.target.value)} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            className="form-control" id="register-code-2" name="register-code-2"
                                            placeholder="code" autoComplete="off"
                                            title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters" />
                                    </div>

                                </form>

                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="secondary"
                                    outline
                                    className="waves-effect"
                                    type="submit"
                                    onClick={confirmSignUp}>
                                    ยืนยันการสมัคร
                                </Button>
                                <Button
                                    color="secondary"
                                    outline
                                    type="submit"
                                    onClick={resendCode}
                                    className="waves-effect">
                                    ส่งรหัสอีกครั้ง
                                </Button>

                            </ModalFooter>
                        </Modal>

                    </CardBody>

                </Card>
            </Container>

        </React.Fragment>
    );

}
export default Register;
