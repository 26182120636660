import React, { Component, useEffect, memo , useState} from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

//Fake backend
import fakeBackend from './helpers/AuthType/fakeBackend';

// Amplify
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
require('dotenv').config()
//Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASEURL,
// 	projectId: process.env.REACT_APP_PROJECTID,
// 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// 	appId: process.env.REACT_APP_APPID,
// 	measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App(props) {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {};
	// 	this.getLayout = this.getLayout.bind(this);
	// }

	/**
   * Returns the layout
   */

	const url = window.location.pathname.split('/').pop();
	const [isAuthProtected, setIsAuthProtected] = useState(false);
	useEffect(() => {

		Auth.currentAuthenticatedUser({
			bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((user) => {
				setIsAuthProtected(true)
			})
			.catch((err) => {
				setIsAuthProtected(false)

			});

	}, [url]);

	const getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};


	const Layout = getLayout();

	return (
		<React.Fragment>
			<BrowserRouter basename={'/dealerplatform'}>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
						/>
					))}

					{authProtectedRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={Layout}
							component={route.component}
							key={idx}
							isAuthProtected={isAuthProtected}
						/>
					))}
					
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);

}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default connect(mapStateToProps, null)(memo(App));
