import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Container } from "reactstrap";

import Editable from "react-bootstrap-editable";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';



function HowSellCar(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     breadcrumbItems : [
  //       { title : "Forms", link : "#" },
  //       { title : "Form Xeditable", link : "#" },
  //     ],
  //   };
  // }

  // render() {
  const confirmElement = (
    <button type="submit" className="btn btn-success editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>
  );
  /** Cancel button */
  const cancelElement = (
    <button type="button" className="btn btn-danger editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>

          {/* <Breadcrumbs title="Form Xeditable" breadcrumbItems={this.state.breadcrumbItems} /> */}

          <Row>
            <Col>
              <Card>
                <CardBody>
                 
    
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
// }

export default HowSellCar;
//