import axios from "axios";

export const httpClient = axios.create({
  baseURL: "https://mottoraka.com/api2/v1/",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "X-API-KEY": "a3626787-2447-426d-a532-94dc8e345779",
  },
});
