import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Label,
  Input,
  Form,
  Container,
  Button,
} from "reactstrap";
// import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
// import carList from "../../../jsons/car10y_ymmt.json";
import carListV2 from "../../../jsons/common-list-v3.json";
import { label } from "@aws-amplify/ui";

// console.log(carListTrim)
const colorCar = [
  {
    options: [
      { value: "white", label: "สีขาว" },
      { value: "gold", label: "สีทอง" },
      { value: "grey", label: "สีเทา" },
      { value: "silver", label: "สีเงิน" },
      { value: "blue", label: "สีน้ำเงิน" },
      { value: "light-blue", label: "สีฟ้า" },
      { value: "green", label: "สีเขียว" },
      { value: "black", label: "สีดำ" },
      { value: "cream", label: "สีครีม" },
      { value: "brown", label: "สีน้ำตาล" },
      { value: "yellow", label: "สีเหลือง" },
      { value: "orange", label: "สีส้ม" },
      { value: "red", label: "สีแดง" },
      { value: "pink", label: "สีชมพู" },
      { value: "purple", label: "สีม่วง" },
      { value: "other", label: "อื่นๆ" },
    ],
  },
];
const owner = [
  {
    options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
    ],
  },
];

function PrFormCarPrice2(props) {
  const [makeGroup, setMakeGroup] = useState([{ options: [] }]);
  const [yearGroup, setYearGroup] = useState([{ options: [] }]);
  const [modelGroup, setModelGroup] = useState([{ options: [] }]);
  const [trimGroup, setTrimGroup] = useState([{ options: [] }]);

  useEffect(() => {
    const data = Object.keys(carListV2);
    let options = [];
    data.sort();
    data.map((data) => {
      options.push({ label: data, value: data });
    });
    setMakeGroup(options);
  }, []);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedTrim, setSelectedTrim] = useState(null);

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [btNext, setBtNext] = useState(0);

  const [mileage, setMileage] = useState(null);

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [activeTab, setActiveTab] = useState("Poor");
  const [activeTabProgress, setActiveTabProgress] = useState(null);
  const toggleTabProgress = (tab) => {
    setActiveTab(tab);
  };
  const history = useHistory();
  const redirectPriceGuid = () => {
    console.log("enter");
    const trimId = "0011";
    const detail =
      carListV2[selectedMake.value][selectedYear.value][selectedModel.value][
        selectedTrim.value
      ];

    console.log(detail);
    let link =
      "/price-guide?year=" +
      selectedYear.value +
      "&make=" +
      selectedMake.value +
      "&model=" +
      selectedModel.value +
      "&trim=" +
      selectedTrim.value +
      "&color=" +
      selectedColor.value +
      "&owner=" +
      selectedOwner.value +
      "&mileage=" +
      mileage +
      "&condition=" +
      activeTab +
      "&trimId=" +
      trimId +
      "&engine=" +
      (detail.engine || "2.0") +
      "&msrpParam=" +
      (detail.msrp || 1000);

    history.push(link);
  };

  const handleSelectMake = (e) => {
    setSelectedYear(null);
    setSelectedModel(null);
    setSelectedTrim(null);
    setModelGroup([{ options: [] }]);
    setTrimGroup([{ options: [] }]);
    setSelectedMake(e);
    let options = [];
    const data = Object.keys(carListV2[e.value]);
    data.sort();
    data.map((data) => {
      options.push({ label: data, value: data });
    });
    setYearGroup(options);
    // console.log(options)
    setBtNext(0);
  };

  const handleSelectYear = (e) => {
    setSelectedYear(e);
    setSelectedTrim(null);

    let options = [];
    const data = Object.keys(carListV2[selectedMake.value][e.value]);
    data.sort();
    data.map((data) => {
      options.push({ label: data, value: data });
    });
    setModelGroup(options);
    setTrimGroup([{ options: [] }]);
    setBtNext(0);
  };

  const handleSelectModel = (e) => {
    setSelectedTrim(null);
    setSelectedModel(e);
    let options = [];
    const data = Object.keys(
      carListV2[selectedMake.value][selectedYear.value][e.value]
    );
    data.sort();
    data.map((data) => {
      options.push({ label: data, value: data });
    });
    setTrimGroup(options);
    setBtNext(0);
  };
  const handleSelectTrim = (e) => {
    setSelectedTrim(e);
    {
      if (e && mileage && selectedColor && selectedOwner) {
        setBtNext(1);
      }
    }
  };
  const handleSelectColor = (selectedColor) => {
    setSelectedColor(selectedColor);
    if (selectedTrim && mileage && selectedColor && selectedOwner) {
      setBtNext(1);
    }
  };
  const handleSelectOwner = (selectedOwner) => {
    setSelectedOwner(selectedOwner);
    if (selectedTrim && mileage && selectedColor && selectedOwner) {
      setBtNext(1);
    }
  };
  const handleMileage = (e) => {
    const value = e.target.value.replace(/\+|-/gi, "");
    setMileage(value);
    if (selectedTrim && value && selectedColor && selectedOwner) {
      setBtNext(1);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="ราคารถยนต์ (New Data's Models)"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Card>
              <CardBody>
                <Col md="12">
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <Form>
                      <Row>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">ยี่ห้อ</Label>
                                  <Select
                                    value={selectedMake}
                                    onChange={handleSelectMake}
                                    options={makeGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">ปี</Label>
                                  <Select
                                    value={selectedYear}
                                    onChange={handleSelectYear}
                                    options={yearGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">รุ่น</Label>
                                  <Select
                                    value={selectedModel}
                                    onChange={handleSelectModel}
                                    options={modelGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">รุ่นย่อย</Label>
                                  <Select
                                    value={selectedTrim}
                                    onChange={handleSelectTrim}
                                    options={trimGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">สี</Label>
                                  <Select
                                    value={selectedColor}
                                    onChange={handleSelectColor}
                                    options={colorCar}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    จำนวนเจ้าของ
                                  </Label>
                                  <Select
                                    value={selectedOwner}
                                    onChange={handleSelectOwner}
                                    options={owner}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">ระยะทาง</Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    // value={mileage}
                                    onChange={handleMileage}
                                    // required
                                  />
                                </div>
                              </Col>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <Row>
                                <Col sm="6">
                                  <label className="form-label">
                                    สภาพรถของคุณเป็นอย่างไร?
                                  </label>

                                  <div className="form-check mb-3">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="rd_condition"
                                      id="rd_fair"
                                      className={classnames({
                                        active: activeTab === "Poor",
                                      })}
                                      onClick={() => {
                                        toggleTabProgress("Poor");
                                      }}
                                      defaultChecked
                                    />
                                    <Label className="form-check-label">
                                      พอใช้
                                    </Label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="rd_condition"
                                      id="rd_good"
                                      className={classnames({
                                        active: activeTab === "Average",
                                      })}
                                      onClick={() => {
                                        toggleTabProgress("Average");
                                      }}
                                    />
                                    <Label className="form-check-label">
                                      ปานกลาง
                                    </Label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="rd_condition"
                                      id="rd_verygood"
                                      className={classnames({
                                        active: activeTab === "Good",
                                      })}
                                      onClick={() => {
                                        toggleTabProgress("Good");
                                      }}
                                    />
                                    <Label className="form-check-label">
                                      ดี
                                    </Label>
                                  </div>
                                  <div className="form-check mb-3">
                                    <Input
                                      className="form-check-input"
                                      type="radio"
                                      name="rd_condition"
                                      id="rd_excellent"
                                      className={classnames({
                                        active: activeTab === "Excellent",
                                      })}
                                      onClick={() => {
                                        toggleTabProgress("Excellent");
                                      }}
                                    />
                                    <Label className="form-check-label">
                                      ดีเยี่ยม
                                    </Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <ul className="list-unstyled mb-0">
                                    <TabContent
                                      activeTab={activeTab}
                                      className="twitter-bs-wizard-tab-content"
                                    >
                                      <TabPane tabId={"Poor"}>
                                        <ul>
                                          <li>
                                            มีรอยถอดซ่อมหรือเปลี่ยนคานซับแรงชนหน้า-หลังถึงคานแต่ไม่ถึงโครงสร้างหลัก
                                          </li>
                                          <li>
                                            มีการถอด/ซ่อม/เปลี่ยนชิ้นส่วนตัวถัง
                                            โครงสร้างเสาประตูยังคงสภาพเดิม
                                          </li>
                                          <li>มีรอยบุบครูดถึงเนื้อ โลหะ</li>
                                          <li>รอบตัวรถมีร่องรอยการซ่อม</li>
                                          <li>
                                            รถน้ำเข้า มีสนิมใต้เบาะ/ใต้คอนโซล
                                            แต่ไม่ปรากฏระดับน้ำ
                                          </li>
                                          <li>รถเคยติดแก๊สมา</li>
                                          <li>
                                            สัญญาณเครื่องยนต์, ABS,
                                            ถุงลมนิรภัยปรากฏ
                                          </li>
                                          <li>
                                            รถเปลี่ยนเครื่องยนต์/สี
                                            มาและสภาพใช้งานไม่ปกติ
                                            ตามทีระบุในเล่มทะเบียน
                                          </li>
                                        </ul>
                                      </TabPane>
                                      <TabPane tabId={"Average"}>
                                        <ul>
                                          <li>ต้องมีการซ่อมเล็กน้อย</li>
                                          <li>
                                            มีการเก็บสี, มีรอยบุบ,
                                            มีรอยถลอกเล็กน้อย
                                          </li>
                                          <li>
                                            ไม่มีรอยถอดน้อตหรือชิ้นส่วนอุปกรณ์ตัวถัง
                                          </li>
                                          <li>
                                            มีสนิมเล็กน้อยซึ่งต้องทำการซ่อม
                                          </li>
                                          <li>ล้อมีสภาพบิ่นหรือรอยขีดข่วน</li>
                                          <li>ภายในตัวรถอยู่ในสภาพดี</li>
                                          <li>
                                            ล้อต้องเป็นขนาดที่มาตรฐานของรถ
                                            และอยู่ในสภาพที่ไม่ต้องเปลี่ยนใหม่
                                          </li>
                                          <li>
                                            ห้องเครื่องสะอาดหรืออาจะมีรอยรั่วเล็กน้อยซึ่งต้องทำการซ่อมแซม
                                          </li>
                                        </ul>
                                      </TabPane>
                                      <TabPane tabId={"Good"}>
                                        <ul>
                                          <li>มีรอยขีดข่วน มีรอยบุบเล็กน้อย</li>
                                          <li>ต้องมีการซ่อมแซมเล็กน้อย</li>
                                          <li>ภายในตัวรถอยู่ในสภาพดี</li>
                                          <li>
                                            ห้องเครื่องสะอาดและปราศจากรอยรั่ว
                                          </li>
                                          <li>ล้อรถอยู่ในสภาพใหม่</li>
                                          <li>
                                            ดอกยางล้อรถต้องมีสภาพใช้งานได้ดี
                                            และมาตราฐาน
                                          </li>
                                          <li>ปราศจากการตกแต่งเพิ่มเติม</li>
                                          <li>ไม่มีการซ่อมสีตัวถัง</li>
                                          <li>ปราศจากสนิมในทุกส่วน</li>
                                          <li>
                                            ไม่มีประวัติการขโมย ผิดกฎหมาย
                                            หรือจดทะเบียนไม่ถูกต้อง
                                          </li>
                                        </ul>
                                      </TabPane>
                                      <TabPane tabId={"Excellent"}>
                                        <ul>
                                          <li>รถอยู่ในสภาพใหม่ ไม่มีริ้วรอย</li>
                                          <li>
                                            ไม่มีการทำสี ไม่มีการซ่อมแซม
                                            ไม่มีการเปลี่ยนชิ้นส่วนตัวถัง
                                          </li>
                                          <li>ภายในตัวรถอยู่ในสภาพดี</li>
                                          <li>
                                            ห้องเครื่องรถอยู่ในสภาพสะอาดและปราศจากรอยรั่ว
                                          </li>
                                          <li>ล้อรถอยู่ในสภาพใหม่</li>
                                          <li>
                                            ยางล้อรถอยู่ในสภาพใหม่และตามมาตรฐาน
                                          </li>
                                          <li>รถไม่มีประวัติการชน</li>
                                          <li>ปราศจากสนิมในทุกส่วน</li>
                                          <li>ปราศจากการตกแต่งเพิ่มเติม</li>
                                          <li>
                                            ไม่มีประวัติการขโมย ผิดกฎหมาย
                                            หรือจดทะเบียนไม่ถูกต้อง
                                          </li>
                                        </ul>
                                      </TabPane>
                                    </TabContent>
                                  </ul>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <div class="dealer-button-carpirce">
                        <Button
                          color="primary"
                          size="lg"
                          className={
                            btNext === 0
                              ? "waves-effect waves-light me-1 disabled"
                              : "waves-effect waves-light me-1"
                          }
                          onClick={() => {
                            redirectPriceGuid();
                          }}
                        >
                          เช็คเลย
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PrFormCarPrice2;
