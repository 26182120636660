import React, { Component, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { Auth } from "aws-amplify";
import { withRouter, Link, useHistory } from 'react-router-dom';

import { logoutUser } from '../../store/actions';

function Logout(props) {
    const history = useHistory();
    localStorage.removeItem("idDealer");
    localStorage.removeItem("emailDealer");
    localStorage.removeItem("userName");
    localStorage.removeItem("dealerType");
    localStorage.removeItem("authUser");
    useEffect(() => {
        Auth.signOut()
            .then((res) => {
                window.location.href = '/dealerplatform/login';
            })
            .catch((err) => {
            });
    }, []);
    return (
        <React.Fragment>
            <h1>&nbsp;</h1>
        </React.Fragment>
    );

}

export default withRouter(connect(null, { logoutUser })(memo(Logout)));

