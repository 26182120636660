import React, { useEffect, useState } from 'react';
import { Card, CardBody, Table } from "reactstrap";

function Sources(props) {

    const [carView, setCarView] = useState([
        { title: "", thumbnailImage: "", viewCount: "" },

    ]);
    const idDealer = localStorage.getItem("idDealer");

    useEffect(async () => {
        const data = await fetch(process.env.REACT_APP_URL_API + 'cars/top-view?limit=10', {
            method: 'GET',
            headers: {
                'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Length': '<calculated when request is sent>'
            }
        })
            .then(response => response.json())
            .then(async (res) => {
                setCarView(res.data)
            })
            .catch((error) => {
            });
    }, []);

    return (
        <React.Fragment>
            <Card>
                <CardBody>

                    <h4 className="card-title mb-3"> 10 อันดับรถที่มีคนสนใจสูงสุด</h4>
                    <div>
                        <div className="table-responsive mt-4">
                            <Table hover className=" mb-0 table-centered table-nowrap">
                                <tbody>

                                    {carView.map(({ title }) => (
                                        <tr>
                                            <td>
                                                <h5 className="font-size-14 mb-0">{title}</h5>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* </Col> */}
        </React.Fragment >
    );

}

export default Sources;