import React, { Component, useState, useEffect } from "react";
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle, Label, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardColumns, Container, CardGroup, Modal, ModalHeader, ModalBody, Input, ModalFooter, Button } from "reactstrap";

import Editable from "react-bootstrap-editable";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import PhoneInput, { format, normalize } from "react-phone-input-auto-format";
import { useHistory, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import img5 from "https://www.mottoraka.com/wp-content/uploads/2021/01/messageImage_1611293441603.jpg";


function DeakerDetail(props) {
    const history = useHistory();

    const [breadcrumbItems, setBreadcrumbItems] = useState([
        // { title: "Forms", link: "#" },
        // { title: "ราคารถยนต์", link: "#" },
    ]);

    const [email, setEmail] = useState();
    const [fullName, setFullName] = useState();
    const [line, setLine] = useState();
    const [companyName, setCompanyName] = useState();
    const [companyAddress, setCompanyAddress] = useState();
    const [phone, setPhone] = useState();
    const [image, setImage] = useState(['https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/image-banner/default.jpeg']);
    const [facebook, setFacebook] = useState();
    const [website, setWebsite] = useState();
    const [taxId, setTaxId] = useState();
    const emailDealer = localStorage.getItem("emailDealer");
    const idDealer = localStorage.getItem("idDealer");

    const [startModelEditProfile, setStartModelEditProfile] = useState({});


    const [fullNameNew, setFullNameNew] = useState();
    const [lineNew, setLineNew] = useState();
    const [phoneNew, setPhoneNew] = useState();
    const [facebookNew, setFacebookNew] = useState();
    const [taxIdNew, setTaxIdNew] = useState();
    const [companyNameNew, setCompanyNameNew] = useState();
    const [companyAddressNew, setCompanyAddressNew] = useState();
    

    useEffect(() => {
        fetch(process.env.REACT_APP_URL_API + 'dealer/info/?email=' + emailDealer, {
            method: 'GET',
            headers: {
                'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Length': '<calculated when request is sent>'
            }
        })
            .then(response => response.json())
            .then((res) => {
                setCompanyName(res.data.companyName)
                setCompanyAddress(res.data.companyAddress)
                setFullName(res.data.fullName)
                setEmail(res.data.email)
                setPhone(res.data.phone)
                setLine(res.data.line)
                setFacebook(res.data.facebook)
                if (res.data.imageList !== null) {
                    setImage(res.data.imageList)
                }
                setTaxId(res.data.taxId)


            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const toggerEditProfile = (e) => {
        setFullNameNew(fullName)
        setLineNew(line)
        setPhoneNew(phone)
        setFacebookNew(facebook)
        setTaxIdNew(taxId)
        setCompanyNameNew(companyName)
        setCompanyAddressNew(companyAddress)

        setStartModelEditProfile(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();
    }

    const onSubmitEditInfo = () => {
        let dataBody = {
            "dealerId": idDealer,
            "name": fullNameNew,
            "phone": phoneNew,
            "lineId": lineNew,
            "facebook": facebookNew,
            "taxId": taxIdNew,
            "companyName" : companyNameNew,
            "companyAddress" : companyAddressNew
        }
        fetch(process.env.REACT_APP_URL_API + "dealer/info",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-API-KEY': process.env.REACT_APP_X_API_KEY
                },
                method: "PUT",
                body: JSON.stringify(dataBody)
            }).then(response => response.json())
            .then((res) => {
                setFullName(fullNameNew)
                setPhone(phoneNew)
                setLine(lineNew)
                setFacebook(facebookNew)
                setTaxId(taxIdNew)
                setCompanyName(companyNameNew)
                setCompanyAddress(companyAddressNew)
                toggerEditProfile()
            })
            .catch((error) => {
                history.push('/404');
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="ข้้อมูลส่วนตัว" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={5}>
                            <Card>
                                <CardBody>
                                    <Slide>
                                        {image.map((image) => (
                                            <div className="each-slide ">
                                                <CardImg className="img-fluid" src={image} alt="Nazox" />

                                            </div>
                                        ))}
                                    </Slide>
                                    <h4 className="card-title">{companyName}</h4>
                                    <p className="card-title-desc"> {companyAddress}</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={7}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">ข้้อมูลส่วนตัว <a onClick={toggerEditProfile} className="setting-dealer">แก้ไข <i class="fa fa-cog" aria-hidden="true"></i></a></CardTitle>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                ชื่อ
                                            </Col>
                                            <Col xs={10}>
                                                : {fullName}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                อีเมลล์
                                            </Col>
                                            <Col xs={10}>
                                                : {email}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                เบอร์โทรศัพท์
                                            </Col>
                                            <Col xs={10}>
                                                : {phone}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                ไลน์ไอดี
                                            </Col>
                                            <Col xs={10}>
                                                : {line}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                เฟซบุ๊ก url
                                            </Col>
                                            <Col xs={10}>
                                                : {facebook}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col xs={2}>
                                                Tax ID
                                            </Col>
                                            <Col xs={10}>
                                                : {taxId}
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardText>
                                        {/* <small className="text-muted">Last updated 3 mins ago</small> */}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={startModelEditProfile.modal_center}
                        toggle={toggerEditProfile}
                        centered={true}
                    >
                        <ModalHeader toggle={() => setStartModelEditProfile({ modal_static: false })} >
                            แก้ไขข้อมูลส่วนตัว!
                        </ModalHeader>
                        <ModalBody>
                            <form>

                                <div className="form-group">
                                    <Row>
                                        <Col md={12}>
                                            <div className="model-fogotpassword">
                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">ชื่อ - นามสกุล</Label>
                                                <input name="username"
                                                    onChange={(e) => setFullNameNew(e.target.value)}
                                                    value={fullNameNew}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="ชื่อ"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="model-fogotpassword">
                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">ชื่อบริษัท</Label>
                                                <input name="username"
                                                    onChange={(e) => setCompanyNameNew(e.target.value)}
                                                    value={companyNameNew}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="ชื่อบริษัท"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="model-fogotpassword">
                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">ไลน์ไอดี</Label>
                                                <Input
                                                    onChange={(e) => setLineNew(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    value={lineNew}
                                                    required
                                                    placeholder="ไลน์ไอดี" />

                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="model-fogotpassword">
                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">เฟซบุ๊ก url</Label>
                                                <Input
                                                    onChange={(e) => setFacebookNew(e.target.value)}
                                                    value={facebookNew}
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    placeholder="เฟซบุ๊ก url" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="model-fogotpassword">
                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">Tax ID</Label>
                                                <Input
                                                    onChange={(e) => setTaxIdNew(e.target.value)}
                                                    value={taxIdNew}
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    placeholder="Tax ID" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="model-fogotpassword">
                                                <textarea
                                                    style={{height: "100px"}}
                                                    class="form-control"
                                                    maxlength="7000"
                                                    type="text"
                                                    value={companyAddressNew}
                                                    onChange={(e) => setCompanyAddressNew(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={toggerEditProfile}
                                color="danger"
                                outline
                                className="waves-effect waves-light me-1"
                            >
                                ยกเลิก
                            </Button>

                            <Button
                                color="secondary"
                                outline
                                type="submit"
                                onClick={onSubmitEditInfo}
                                className="waves-effect">
                                ยินยัน
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Container>

            </div>
        </React.Fragment>
    );
}
// }

export default DeakerDetail;
//