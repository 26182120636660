import React, { Component, useState, useEffect, memo } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";


import {  Link, useHistory } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';


import { Auth } from "aws-amplify";


function Login(props) {
    const history = useHistory();
    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then((user) => {
                if (localStorage.getItem("authUser")) {
                    history.push('/dashboard'); 
                }
            })
            .catch((err) => {

            });

    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [stateModel, setStateModel] = useState({});
    const [emailResetPassword, setEmailResetPassword] = useState("");
    const [stateModelFoGotPassword, setStateModelFoGotPassword] = useState({});

    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [emailResetPasswordConfirm, setEmailResetPasswordConfirm] = useState("");

    const [startModelRequiredPassword, setStartModelRequiredPassword] = useState({});


    //NEW_PASSWORD_REQUIRED
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const handleSubmit = (e, values) => {
        e.preventDefault();
        signIn();


    }
    const signIn = async () => {

        Auth.signIn(username, password)
            .then((res) => {
                if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const { requiredAttributes } = res.challengeParam;
                    setStartModelRequiredPassword(prevState => ({
                        modal_center: !prevState.modal_center
                    }));
                    removeBodyCss();
                    Auth.completeNewPassword(
                        res,               // the Cognito User Object
                        newPassword,       // the new password
                        // OPTIONAL, the required attributes

                    ).then((user) => {
                        localStorage.setItem("authUser", user.authenticationFlowType);
                        localStorage.setItem("emailDealer", username)
                        localStorage.setItem("userName", username.split("@")[0]);
                        getTypeDealer(username)

                    }).catch(e => {
                        history.push('/login');
                    });
                } else {
                    localStorage.setItem("emailDealer", username)
                    localStorage.setItem("userName", username.split("@")[0]);
                    localStorage.setItem("authUser", res.authenticationFlowType);
                    getTypeDealer(username)

                }

            }).catch((err) => {
                alert(err.message)

            })
    }

    const getTypeDealer = (username) => {
        const data = fetch(process.env.REACT_APP_URL_API + 'dealer/info/?email=' + username, {
            method: 'GET',
            headers: {
                'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                'Content-Type': process.env.REACT_APP_Content_Type,
                'Content-Length': process.env.REACT_APP_Content_Type_Content_Length
            }
        })
            .then(response => response.json())
            .then((res) => {
                localStorage.setItem("idDealer", res.data.dealerId)
                localStorage.setItem("dealerType", res.data.type)
                history.push('/dashboard');
            })
            .catch((error) => {
            });
    }

    const tog_reset_password = (e) => {
        e.preventDefault();
        setStateModel(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();

    }
    const tog_forgot_password = (e) => {
        e.preventDefault();
        setStateModelFoGotPassword(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();

    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const onSubmitResetPassword = (e) => {
        e.preventDefault();
        Auth.forgotPassword(emailResetPassword)
            .then((res) => {
                setStateModel(prevState => ({
                    modal_center: !prevState.modal_center
                }));
                removeBodyCss();
                setStateModelFoGotPassword(prevState => ({
                    modal_center: !prevState.modal_center
                }));
            })
            .catch((err) => {
                console.log(err)
            });
    };
    const onSubmitResetPasswordConfirm = () => {
        Auth.forgotPasswordSubmit(emailResetPasswordConfirm, code, newPassword)
            .then((res) => {
                setStateModelFoGotPassword(prevState => ({
                    modal_center: !prevState.modal_center
                }));
            })
            .catch((err) => {
                console.log(err);
                Auth.signOut()
                    .then(() => {
                        // localStorage.setItem("userBoltechId", "");
                        // if (back2HomePage) backToHomePage();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
    };
    const newPasswordRequired = (e) => {
        e.preventDefault();
        setStartModelRequiredPassword(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();

    }
    const onSubmitNewPasswordRequired = (e) => {
        e.preventDefault();
        if (newPasswordConfirm === newPassword) {
            signIn();
        }
    }
    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/dealerplatform/login" class="">
                                                            <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/image-banner/MottoRaka-1.png" alt="" height="40" class="auth-logo logo-dark mx-auto" />
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">ชื่อผู้ใช้ (อีเมล)</Label>
                                                            <AvField name="username"
                                                                value={username}
                                                                type="text"
                                                                className="form-control"
                                                                id="username"
                                                                validate={{ email: true, required: true }}
                                                                onChange={(e) => setUsername(e.target.value)}
                                                                placeholder="Enter username" />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">รหัสผ่าน</Label>
                                                            <AvField name="password" value={password}
                                                                type="password"
                                                                className="form-control"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                id="userpassword"
                                                                placeholder="Enter password" />
                                                        </div>
                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">เข้าสู่ระบบ</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link onClick={tog_reset_password} className="text-muted"><i className="mdi mdi-lock me-1"></i> ลืมรหัสผ่าน</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>คุณยังไม่ได้เป็นสมาชิกใช่ไหม ? <Link to="/register" className="fw-medium text-primary"> สมัครสมาชิก </Link> </p>
                                                    <p>© 2021 Mottoraka.com All rights reserved.</p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={stateModel.modal_center}
                        toggle={tog_reset_password}
                        centered={true}
                    >
                        <ModalHeader toggle={() => setStateModel({ modal_static: false })}>
                            คุณลืมรหัสผ่าน?
                        </ModalHeader>
                        <ModalBody>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="register-code-2">

                                        ป้อนที่อยู่อีเมลของคุณด้านล่างเพื่อเริ่มกระบวนการรีเซ็ต
                                    </label>
                                    <input name="username"
                                        // value={username}
                                        type="text"
                                        className="form-control"
                                        // id="username"
                                        validate={{ email: true, required: true }}
                                        onChange={(e) => setEmailResetPassword(e.target.value)}
                                        placeholder="กรอกอีเมล" />
                                </div>

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={tog_reset_password}
                                color="danger"
                                outline
                                className="waves-effect waves-light me-1"
                            >
                                ยกเลิก
                            </Button>

                            <Button
                                color="secondary"
                                outline
                                type="submit"
                                onClick={onSubmitResetPassword}
                                className="waves-effect">
                                ยินยัน
                            </Button>

                        </ModalFooter>
                    </Modal>
                    <Modal
                        isOpen={stateModelFoGotPassword.modal_center}
                        toggle={tog_forgot_password}
                        centered={true}
                    >
                        <ModalHeader toggle={() => setStateModelFoGotPassword({ modal_static: false })} >
                            คุณลืมรหัสผ่าน?
                        </ModalHeader>
                        <ModalBody>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="register-code-2">

                                        รหัส OTP จะถูกส่งไปที่อีเมลของคุณ
                                        ป้อนที่อยู่อีเมล รหัส OTP และรหัสผ่านใหม่ด้านล่างเพื่อรีเซ็ตรหัสผ่านของคุณ
                                    </label>
                                    <div className="model-fogotpassword">
                                        <input name="username"
                                            // value={username}
                                            type="text"
                                            className="form-control"
                                            // id="username"
                                            validate={{ email: true, required: true }}
                                            onChange={(e) => setEmailResetPasswordConfirm(e.target.value)}
                                            placeholder="ป้อนอีเมลลื" />
                                    </div>
                                    <div className="model-fogotpassword">
                                        <input type="text" onChange={(e) => setCode(e.target.value)} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            className="form-control" id="register-code-2" name="register-code-2"
                                            placeholder="ป้อนรหัส OTP" autoComplete="off"
                                        />
                                    </div>
                                    <div className="model-fogotpassword">
                                        <Input
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            type="password"
                                            className="form-control"
                                            required
                                            placeholder="ป้อนรหัสผ่านใหม่ของคุณ" />
                                    </div>
                                </div>

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={tog_forgot_password}
                                color="danger"
                                outline
                                className="waves-effect waves-light me-1"
                            >
                                ยกเลิก
                            </Button>

                            <Button
                                color="secondary"
                                outline
                                type="submit"
                                onClick={onSubmitResetPasswordConfirm}
                                className="waves-effect">
                                ยินยัน
                            </Button>

                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={startModelRequiredPassword.modal_center}
                        toggle={newPasswordRequired}
                        centered={true}
                    >
                        <ModalHeader toggle={() => setStartModelRequiredPassword({ modal_static: false })} >
                            เปลี่ยนรหัสผ่าน!
                        </ModalHeader>
                        <ModalBody>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="register-code-2">
                                        เปลี่ยนรหัสผ่านเพื่อยืนยันตัวตน
                                    </label>
                                    <div className="model-fogotpassword">
                                        <input name="username"
                                            value={username}
                                            type="text"
                                            className="form-control"
                                            // id="username"
                                            validate={{ email: true, required: true }}
                                            onChange={(e) => setEmailResetPasswordConfirm(e.target.value)}
                                            placeholder="ป้อนอีเมลลื"
                                        />
                                    </div>

                                    <div className="model-fogotpassword">
                                        <Input
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            type="password"
                                            className="form-control"
                                            required
                                            placeholder="ป้อนรหัสผ่านใหม่ของคุณ" />
                                    </div>
                                    <div className="model-fogotpassword">
                                        <Input
                                            onChange={(e) => setNewPasswordConfirm(e.target.value)}
                                            type="password"
                                            className="form-control"
                                            required
                                            placeholder="ยืนยันรหัสผ่านใหม่ของคุณ" />
                                    </div>
                                </div>

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={newPasswordRequired}
                                color="danger"
                                outline
                                className="waves-effect waves-light me-1"
                            >
                                ยกเลิก
                            </Button>

                            <Button
                                color="secondary"
                                outline
                                type="submit"
                                onClick={onSubmitNewPasswordRequired}
                                className="waves-effect">
                                ยินยัน
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default (memo(Login));