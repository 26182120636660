import React, { Component, useState, useEffect, memo } from "react";
import { Row, Col, Card, Button, CardBody, TabContent, TabPane, NavItem, NavLink, Label, Input, Form, Progress, Container, CardTitle, CardSubtitle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import classnames from 'classnames';
import { Editor } from "react-draft-wysiwyg";
import S3 from 'react-aws-s3';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from "react-select";
import carList from '../../jsons/carAll.json';
import carListTrim from '../../jsons/carAll_s.json';

import stringToStream from "string-to-stream";
import { uploadFile } from 'react-s3';
import { v4 as uuid } from "uuid";
import AWS from 'aws-sdk'
import { useHistory, Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayPush } from "redux-form";
import NumberFormat from "react-number-format";


const colorCar = [
    {
        options: [
            { value: 'white', label: 'สีขาว' },
            { value: 'gold', label: 'สีทอง' },
            { value: 'grey', label: 'สีเทา' },
            { value: 'silver', label: 'สีเงิน' },
            { value: 'blue', label: 'สีน้ำเงิน' },
            { value: 'light-blue', label: 'สีฟ้า' },
            { value: 'green', label: 'สีเขียว' },
            { value: 'black', label: 'สีดำ' },
            { value: 'cream', label: 'สีครีม' },
            { value: 'brown', label: 'สีน้ำตาล' },
            { value: 'yellow', label: 'สีเหลือง' },
            { value: 'orange', label: 'สีส้ม' },
            { value: 'red', label: 'สีแดง' },
            { value: 'pink', label: 'สีชมพู' },
            { value: 'purple', label: 'สีม่วง' },
            { value: 'other', label: 'อื่นๆ' },

        ]
    }
];
const owner = [
    {
        options: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' }
        ]
    }
];



function FormEditCar(props) {

    const queryParams = new URLSearchParams(window.location.search);
    const carIdParams = queryParams.get('carId')
    var dataCar = JSON.parse(localStorage.getItem('dataObject'));
    const history = useHistory();
    if (dataCar.carId !== carIdParams) {
        history.push('/list-car');
    }
    const [yearGroup, setYearGroup] = useState([{ options: [] }]);
    const [makeGroup, setMakeGroup] = useState([{ options: [] }]);
    const [modelGroup, setModelGroup] = useState([{ options: [] }]);
    const [trimGroup, setTrimGroup] = useState([{ options: [] }]);
    const [detailCar, setDetailCar] = useState(null);
    const [galleryImage, setGalleryImage] = useState([]);
    useEffect(async () => {
        await fetch(dataCar.content)
            .then((r) => r.text())
            .then(text => {
                setDetailCar(text)
            })

        const year = (Object.keys(carList));
        year.sort().reverse();
        year.map((data) => {
            yearGroup[0].options.push({ label: data, value: data });
        });
        const make = (Object.keys(carList[dataCar.year]));
        make.sort();
        make.map((data) => {
            makeGroup[0].options.push({ label: data, value: data });
        });
        const model = (Object.keys(carList[dataCar.year][dataCar.make]));
        model.sort();
        model.map((data) => {
            modelGroup[0].options.push({ label: data, value: data });
        });
        const trimName = (Object.keys(carList[dataCar.year][dataCar.make][dataCar.model]));
        trimName.sort();
        trimName.map((data) => {
            trimGroup[0].options.push({ label: data, value: data });
        });
        let arrayImage = [];
        for (let i = 0; i < (dataCar.galleryImage).length; i++) {
            arrayImage.push({ path: i + ".jpg", thumb: dataCar.galleryImage[i] })
        }
        setGalleryImage(arrayImage)

    }, []);

    colorCar[0].options.forEach((data) => {
        if (data.value === dataCar.colorEx) {
            dataCar.colorEx = data
        }
    })

    const [activeTabProgress, setActiveTabProgress] = useState(1);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [progressValue, setProgressValue] = useState(20);
    const [selectNext, setSelectNext] = useState("ถัดไป");
    const [selectedYear, setSelectedYear] = useState({ label: dataCar.year, value: dataCar.year });
    const [selectedMake, setSelectedMake] = useState({ label: dataCar.make, value: dataCar.make });
    const [selectedModel, setSelectedModel] = useState({ label: dataCar.model, value: dataCar.model });
    const [selectedTrim, setSelectedTrim] = useState({ label: dataCar.trimName, value: dataCar.trimName });
    const [mileage, setMileage] = useState(dataCar.mileage);
    const [engine, setEngine] = useState(dataCar.engine);
    const [transmission, setTransmission] = useState(dataCar.transmission);
    const [fuel, setFuel] = useState(dataCar.fuel);
    const [driveTrain, setDriveTrain] = useState(dataCar.driveTrain);
    const [bodyType, setBodyType] = useState(dataCar.carBodyType);
    const [seats, setSeats] = useState(dataCar.seats);
    const [doors, setDoors] = useState(dataCar.door);
    const [price, setPrice] = useState(dataCar.price);
    const [priceExclusive, setPriceExclusive] = useState(dataCar.priceNew);
    const [selectedColor, setSelectedColor] = useState(dataCar.colorEx);

    const [selectOwner, setSelectOwner] = useState({ label: dataCar.owners, value: dataCar.owners });
    const [stateModel, setStateModel] = useState({});
    const [btNext, setBtNext] = useState(1);
    const [characters, updateCharacters] = useState([]);



    const toggleTabProgress = async (e, tab) => {
        e.preventDefault();
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 5) {

                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(20);
                    // setBtNext(false)
                }
                if (tab === 2) {
                    setProgressValue(40)
                    setSelectNext("ถัดไป");
                    // setBtNext(true)
                }
                if (tab === 3) {
                    setProgressValue(65);
                    setSelectNext("ถัดไป");
                }
                if (tab === 4) {
                    setProgressValue(80);
                    setSelectNext("ถัดไป");
                }
                if (tab === 5) {
                    setProgressValue(100);
                    setSelectNext("ยืนยัน");
                }

            }
        }
        if (tab === 6) {
            const imageFiles = await uploadImage(galleryImage)
            const s3 = new AWS.S3({
                accessKeyId: process.env.REACT_APP_S3_AccessKeyId,
                secretAccessKey: process.env.REACT_APP_S3_SecretAccessKey,
                endpoint: process.env.REACT_APP_EndpointS3,
            })

            let S3Config = {
                Bucket: process.env.REACT_APP_BucketName,
                Key: process.env.REACT_APP_DirNameContent + dataCar.carId + ".txt",
                Body: detailCar,
                ACL: "public-read"
            };
            const mileage1 = mileage.replace(/,/g, '');
            let priceExclusive1 = 0;
            if (priceExclusive) {
                priceExclusive1 = priceExclusive.replace(/,/g, '');
            }
            const price1 = price.replace(/,/g, '');   
     
            const fileContent = await s3.upload(S3Config).promise()
            let nameCar = selectedYear.value + "-" + selectedMake.value + "-" + selectedModel.value + "-" + selectedTrim.value;
            let dataBody = {
                "carId": dataCar.carId,
                "content": fileContent.Location,
                "title": selectedYear.value + " " + selectedMake.value + " " + selectedModel.value + " " + selectedTrim.value,
                "name": nameCar.toLowerCase(),
                "year": parseInt(selectedYear.value),
                "make": selectedMake.value,
                "model": selectedModel.value,
                "trimName": selectedTrim.value,
                "fuel": fuel,
                "transmission": transmission,
                "driveTrain": driveTrain,
                "colorIn": null,
                "colorEx": selectedColor.value,
                "carBodyType": bodyType,
                "mileage": parseInt(mileage1),
                "door": doors,
                "seats": seats,
                "engine": engine,
                "useCar": "used",
                "price": parseInt(price1),
                "priceMsrp": null,
                "owners": parseInt(selectOwner.value),
                "priceNew": parseInt(priceExclusive1),
                "thumbnailImage": imageFiles[0],
                "galleryImage": imageFiles
            }
            const updateCar = await fetch(process.env.REACT_APP_URL_API + "cars/sell-cars/",
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-API-KEY': process.env.REACT_APP_X_API_KEY
                    },
                    method: "PUT",
                    body: JSON.stringify(dataBody)
                }).then(response => response.json())
                .then((res) => {
                    setStateModel(prevState => ({
                        modal_center: !prevState.modal_center
                    }));

                })
                .catch((error) => {
                    history.push('/404');
                });
        }
    }
    let foderImge = () => {
        let currentYear = new Date().getFullYear();
        let monthNumber = (new Date().getMonth() + 1);
        let foderName = currentYear + "/" + monthNumber + "/";
        return foderName;
    };
    const uploadImage = async (files) => {
        if (!files) return [];
        let array = [];
        let S3Config = {
            bucketName: process.env.REACT_APP_BucketName,
            region: process.env.REACT_APP_Region,
            dirName: process.env.REACT_APP_DirName,
            accessKeyId: process.env.REACT_APP_S3_AccessKeyId,
            secretAccessKey: process.env.REACT_APP_S3_SecretAccessKey,
            s3Url: process.env.REACT_APP_S3Url,
        };
        const ReactS3Client = new S3(S3Config);
        for (var i = 0; i < files.length; i++) {
            if (!files[i].thumb.indexOf('blob')) {
                let nameImage = foderImge() + uuid();
                await ReactS3Client.uploadFile(files[i], nameImage)
                    .then((res) => {
                        array.push(
                            res.location,
                        );
                    })
                    .catch((err) => {
                        history.push('/404');
                    })
            } else {
                array.push(files[i].thumb)
            }
        }
        return array;
    };

    const handleSelectYear = selectedYear => {
        setSelectedMake(null);
        setSelectedModel(null);
        setSelectedTrim(null);
        setSelectedYear(selectedYear);
        let options = [];
        const data = (Object.keys(carList[selectedYear.value]));
        data.sort();
        data.map((data) => {
            options.push({ label: data, value: data });
        });
        setMakeGroup(options)
        setModelGroup([{ options: [] }])
        setTrimGroup([{ options: [] }])
        setBtNext(0)

    };
    const handleSelectMake = selectedMake => {
        setSelectedMake(selectedMake);
        {
            setSelectedModel(null);
            setSelectedTrim(null);
            let options = [];
            const data = (Object.keys(carList[selectedYear.value][selectedMake.value]));
            data.sort();
            data.map((data) => {
                options.push({ label: data, value: data });
            });
            setModelGroup(options)
            setTrimGroup([{ options: [] }])
        }
        setBtNext(0)
    };
    const handleSelectModel = selectedModel => {
        setSelectedModel(selectedModel);
        {
            setSelectedTrim(null);
            let options = [];
            const data = (Object.keys(carList[selectedYear.value][selectedMake.value][selectedModel.value]));
            data.sort();
            data.map((data) => {
                options.push({ label: data, value: data });
            });
            setTrimGroup(options)

        }
        setBtNext(0)
    };
    const handleSelectTrim = selectedTrim => {
        setSelectedTrim(selectedTrim);
        {
            const trimId = (carList[selectedYear.value][selectedMake.value][selectedModel.value][selectedTrim.value]);
            const dataTrim = (carListTrim[trimId]);
            setEngine(dataTrim.engine);
            setTransmission(dataTrim.transmission);
            setFuel(dataTrim.fuel);
            setDriveTrain(dataTrim.driveTrain);
            setBodyType(dataTrim.bodyType);
            setSeats(dataTrim.seats);
            setDoors(dataTrim.doors);
        }
        setBtNext(1)
    };
    const handleMileage = (e) => {
        const value = e.target.value.replace(/\+|-/ig, '');
        setMileage(value);
    };
    const handleEngine = (e) => {
        setEngine(e.target.value)
    };
    const handleTransmission = (e) => {
        setTransmission(e.target.value)
    };
    const handleFuel = (e) => {
        setFuel(e.target.value)
    };
    const handleDriveTrain = (e) => {
        setDriveTrain(e.target.value)
    };
    const handleBodyType = (e) => {
        setBodyType(e.target.value)
    };
    const handleSeats = (e) => {
        setSeats(e.target.value)
    };
    const handleDoors = (e) => {
        setDoors(e.target.value)
    };
    const handlePrice = (e) => {
        setPrice(e.target.value)
    };
    const handlePriceExclusive = (e) => {
        setPriceExclusive(e.target.value)
    };
    const handleSelectColor = selectedColor => {
        setSelectedColor(selectedColor);
    };
    const handleSelectOwner = value => {
        setSelectOwner(value);
    };
    const handleDetailCar = (e) => {
        const value = e.target.value;
        setDetailCar(value);
    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const tog_center = () => {
        setStateModel(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();
    }
    const onSubmitModel = () => {
        localStorage.setItem("dataObject", "")
        history.push('/list-car');
    }

    const handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                thumb: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );
        // uploadImage(files)

        let galleryImageBefore = galleryImage
    
        galleryImageBefore.push(...files)

        setGalleryImage(galleryImageBefore)
        let destination = {
            destination: { droppableId: "characters", index: 1 }, combine: null, draggableId: '0.jpg', mode: "FLUID", reason: "DROP", source: {
                "index": 1,
                "droppableId": "characters"
            },
            type: "DEFAULT"
        };
        setBtNext(1)
        handleOnDragEnd(destination)
    };
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(galleryImage);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setGalleryImage(items)
    }
    const onClickRemoveImage = (e) => {
        setGalleryImage(galleryImage.filter((a) => a.path !== e))
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="แก้ไขรถ" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div id="progrss-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 1 })} >
                                                    <span className="step-number">01</span>
                                                    <span className="step-title">รายละเอียดรถ</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 2 })} >
                                                    <span className="step-number">02</span>
                                                    <span className="step-title">รายละเอียดอื่นๆ</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 3 })}  >
                                                    <span className="step-number">03</span>
                                                    <span className="step-title">รายละเอียด</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 4 })}  >
                                                    <span className="step-number">04</span>
                                                    <span className="step-title">รูป</span>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 5 })}  >
                                                    <span className="step-number">05</span>
                                                    <span className="step-title">Confirm Detail</span>
                                                </NavLink>
                                            </NavItem>
                                        </ul>

                                        <div id="bar" className="mt-4">
                                            <Progress color="success" striped animated value={progressValue} />
                                        </div>
                                        <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-firstname-input14">ปี *</Label>

                                                                <Select
                                                                    value={selectedYear}
                                                                    onChange={handleSelectYear}
                                                                    options={yearGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-lastname-input15">ยี่ห้อ *</Label>
                                                                <Select
                                                                    value={selectedMake}
                                                                    onChange={handleSelectMake}
                                                                    options={makeGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">รุ่น *</Label>
                                                                <Select
                                                                    value={selectedModel}
                                                                    onChange={handleSelectModel}
                                                                    options={modelGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-email-input17">รุ่นย่อย *</Label>
                                                                <Select
                                                                    value={selectedTrim}
                                                                    onChange={handleSelectTrim}
                                                                    options={trimGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="previous disabled  waves-effect waves-light me-1"
                                                        >
                                                            ย้อนกลับ
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                        >
                                                            ถัดไป
                                                        </Button>
                                                    </ul>
                                                </Form>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <div>
                                                    <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-pancard-input18">ระยะทาง *</Label>
                                                                    <NumberFormat
                                                                        className="form-control"
                                                                        value={mileage}
                                                                        onChange={handleMileage}
                                                                        thousandSeparator={true}
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-vatno-input19">เครื่องยนต์</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={engine}
                                                                        onChange={handleEngine}
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-cstno-input20">เกียร์</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={transmission}
                                                                        onChange={handleTransmission}
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-servicetax-input21">เชื้อเพลิง</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={fuel}
                                                                        onChange={handleFuel}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-companyuin-input22">ขับเคลื่อน</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={driveTrain}
                                                                        onChange={handleDriveTrain}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-declaration-input23">ประเภทรถ</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={bodyType}
                                                                        onChange={handleBodyType}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-companyuin-input22">จำนวนที่นั่ง</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={seats}
                                                                        onChange={handleDoors}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-declaration-input23">จำนวนประตู</Label>
                                                                    <Input
                                                                        type="ืnumber"
                                                                        className="form-control"
                                                                        value={doors}
                                                                        onChange={handleSeats}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">ราคาเต็ม</Label>
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        className="form-control"
                                                                        value={price}
                                                                        onChange={handlePrice}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">ราคาพิเศษ</Label>
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        className="form-control"
                                                                        value={priceExclusive}
                                                                        onChange={handlePriceExclusive}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">สี *</Label>
                                                                    <Select
                                                                        value={selectedColor}
                                                                        onChange={handleSelectColor}
                                                                        options={colorCar}
                                                                        classNamePrefix="select2-selection"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">จำนวนเจ้าของ *</Label>
                                                                    <Select
                                                                        value={selectOwner}
                                                                        onChange={handleSelectOwner}
                                                                        options={owner}
                                                                        classNamePrefix="select2-selection"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                            <Button
                                                                // type="submit"
                                                                // (e) => toggleTabProgress(e, (activeTabProgress + 1))
                                                                onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                                color="primary"
                                                                className="previous  waves-effect waves-light me-1"
                                                            >
                                                                ย้อนกลับ
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className=" waves-effect waves-light me-1 btNext-sell-car"
                                                            >
                                                                ถัดไป
                                                            </Button>
                                                        </ul>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <div>
                                                    <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardBody>
                                                                        <CardTitle>เพิ่มรายละเอียดเกี่ยวกับรถ</CardTitle>
                                                                        <CardSubtitle className="mb-3">
                                                                        </CardSubtitle>

                                                                        <Form method="post">
                                                                            <textarea
                                                                                class="form-control"
                                                                                maxlength="7000"
                                                                                type="text"
                                                                                value={detailCar}
                                                                                onChange={handleDetailCar}
                                                                            />
                                                                        </Form>

                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                            <Button
                                                                // type="submit"
                                                                // (e) => toggleTabProgress(e, (activeTabProgress + 1))
                                                                onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                                color="primary"
                                                                className="previous  waves-effect waves-light me-1"
                                                            >
                                                                ย้อนกลับ
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className=" waves-effect waves-light me-1 btNext-sell-car"
                                                            >
                                                                ถัดไป
                                                            </Button>
                                                        </ul>

                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={4}>

                                                <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                    <Dropzone
                                                        onDrop={acceptedFiles =>
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                    </div>
                                                                    <h4>Drop files here or click to upload.</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                    >
                                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                                            <Droppable droppableId="characters">
                                                                {(provided) => (
                                                                    <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                                                        {galleryImage.map(({ path, thumb }, index) => {
                                                                            return (
                                                                                <Draggable key={path} draggableId={path} index={index}>
                                                                                    {(provided) => (
                                                                                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <Row>
                                                                                                <Col lg="1">
                                                                                                    <p>
                                                                                                        {index}
                                                                                                    </p>
                                                                                                </Col>
                                                                                                <Col lg="5">
                                                                                                    <div className="characters-thumb">
                                                                                                        <img className="image-sellcar" src={thumb} />
                                                                                                    </div>
                                                                                                    <p>
                                                                                                    </p>
                                                                                                </Col>
                                                                                                <Col lg="6">
                                                                                                    <Button color="link" className="waves-effect me-1" style={{ color: "red", float: 'right' }} onClick={() => onClickRemoveImage(path)}>
                                                                                                        <i className="mdi mdi-trash-can font-size-18"></i>
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </li>

                                                                                    )}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </ul>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </div>
                                                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                        <Button
                                                            onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                            color="primary"
                                                            className="previous  waves-effect waves-light me-1"
                                                        >
                                                            ย้อนกลับ
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                        >
                                                            ถัดไป
                                                        </Button>
                                                    </ul>
                                                </Form>
                                            </TabPane>
                                            <TabPane tabId={5}>
                                                <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                    <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                        <Button
                                                            // type="submit"
                                                            // (e) => toggleTabProgress(e, (activeTabProgress + 1))
                                                            onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                            color="primary"
                                                            className="previous  waves-effect waves-light me-1"
                                                        >
                                                            ย้อนกลับ
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className=" waves-effect waves-light me-1 btNext-sell-car"
                                                        >
                                                            ยืนยัน
                                                        </Button>
                                                    </ul>
                                                </Form>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={stateModel.modal_center}
                        toggle={tog_center}
                        centered={true}
                        className="modelCenter"

                    >
                        <ModalBody className="bodyModelCenet">
                            <h1>อัพเดตรถสำเร็จ</h1>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline
                                type="submit"
                                onClick={onSubmitModel}
                                className="waves-effect">
                                ปิด
                            </Button>
                        </ModalFooter>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );

}

export default memo(FormEditCar);
