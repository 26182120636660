import React, { Component, useState, useEffect, memo } from "react";
import { Row, Col, Card, Button, CardBody, TabContent, TabPane, NavItem, NavLink, Label, Input, Form, Progress, Container, CardTitle, CardSubtitle, Modal, ModalBody, ModalFooter } from "reactstrap";

import classnames from 'classnames';
import S3 from 'react-aws-s3';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from "react-select";
import carList from '../../jsons/carAll.json';
import carListTrim from '../../jsons/carAll_s.json';

import Dropzone from "react-dropzone";
import stringToStream from "string-to-stream";
import { uploadFile } from 'react-s3';
import { v4 as uuid } from "uuid";
import NumberFormat from "react-number-format";
import { useHistory, Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



const colorCar = [
    {
        options: [
            { value: 'white', label: 'สีขาว' },
            { value: 'gold', label: 'สีทอง' },
            { value: 'grey', label: 'สีเทา' },
            { value: 'silver', label: 'สีเงิน' },
            { value: 'blue', label: 'สีน้ำเงิน' },
            { value: 'light-blue', label: 'สีฟ้า' },
            { value: 'green', label: 'สีเขียว' },
            { value: 'black', label: 'สีดำ' },
            { value: 'cream', label: 'สีครีม' },
            { value: 'brown', label: 'สีน้ำตาล' },
            { value: 'yellow', label: 'สีเหลือง' },
            { value: 'orange', label: 'สีส้ม' },
            { value: 'red', label: 'สีแดง' },
            { value: 'pink', label: 'สีชมพู' },
            { value: 'purple', label: 'สีม่วง' },
            { value: 'other', label: 'อื่นๆ' },

        ]
    }
];
const owner = [
    {
        options: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' }
        ]
    }
];



function FormSellCar(props) {
    const [yearGroup, setYearGroup] = useState([{ options: [] }]);
    const [makeGroup, setMakeGroup] = useState([{ options: [] }]);
    const [modelGroup, setModelGroup] = useState([{ options: [] }]);
    const [trimGroup, setTrimGroup] = useState([{ options: [] }]);
    const [characters, updateCharacters] = useState([]);
    const history = useHistory();
    let foderImge = () => {
        let currentYear = new Date().getFullYear();
        let monthNumber = (new Date().getMonth() + 1);
        let foderName = currentYear + "/" + monthNumber + "/";
        return foderName;
    };

    useEffect(() => {
        let options = [];
        const data = (Object.keys(carList));
        data.sort().reverse();
        data.map((data) => {
            options.push({ label: data, value: data });
        });
        setYearGroup(options)
    }, []);


    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    const [activeTab, setActiveTab] = useState(1);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    const [progressValue, setProgressValue] = useState(25);

    const [selectedYear, setSelectedYear] = useState();
    const [selectedMake, setSelectedMake] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedTrim, setSelectedTrim] = useState("");


    const [mileage, setMileage] = useState(null);
    const [engine, setEngine] = useState(null);
    const [transmission, setTransmission] = useState(null);
    const [fuel, setFuel] = useState(null);
    const [driveTrain, setDriveTrain] = useState(null);
    const [bodyType, setBodyType] = useState(null);
    const [seats, setSeats] = useState(null);
    const [doors, setDoors] = useState(null);
    const [price, setPrice] = useState(null);
    const [priceExclusive, setPriceExclusive] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectNext, setSelectNext] = useState("ถัดไป");
    const [detailCar, setDetailCar] = useState(null);
    const [selectOwner, setSelectOwner] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [downloadLink, setDownloadLink] = useState('')
    const [imageArray, setImageArray] = useState([]);
    const [btNext, setBtNext] = useState(0);
    const [stateModel, setStateModel] = useState({});


    const handleMileage = (e) => {
        const value = parseInt(e.target.value);
        if (value > 1000000) setMileage(999999);
        if (mileage && price && selectedColor && selectOwner) {
            setBtNext(1)
        }
    };
    const handleDetailCar = (e) => {
        const value = e.target.value;
        setDetailCar(value);
        setBtNext(1)
    };

    const handleSelectYear = selectedYear => {
        setSelectedYear(selectedYear);
        {
            setSelectedMake(null);
            setSelectedModel(null);
            setSelectedTrim(null);
            let options = [];
            const data = (Object.keys(carList[selectedYear.value]));
            data.sort();
            data.map((data) => {
                options.push({ label: data, value: data });
            });
            setMakeGroup(options)
            setModelGroup([{ options: [] }])
            setTrimGroup([{ options: [] }])
        }
        setBtNext(0)
    };
    const handleSelectMake = selectedMake => {
        setSelectedMake(selectedMake);
        {
            setSelectedModel(null);
            setSelectedTrim(null);
            let options = [];
            const data = (Object.keys(carList[selectedYear.value][selectedMake.value]));
            data.sort();
            data.map((data) => {
                options.push({ label: data, value: data });
            });
            setModelGroup(options)
            setTrimGroup([{ options: [] }])
        }
        setBtNext(0)

    };
    const handleSelectModel = selectedModel => {
        setSelectedModel(selectedModel);
        {
            setSelectedTrim(null);
            let options = [];
            const data = (Object.keys(carList[selectedYear.value][selectedMake.value][selectedModel.value]));
            data.sort();
            data.map((data) => {
                options.push({ label: data, value: data });
            });
            setTrimGroup(options)
        }
        setBtNext(0)
    };
    const handleSelectTrim = selectedTrim => {
        setSelectedTrim(selectedTrim);
        {
            const trimId = (carList[selectedYear.value][selectedMake.value][selectedModel.value][selectedTrim.value]);
            const dataTrim = (carListTrim[trimId]);
            setEngine(dataTrim.engine);
            setTransmission(dataTrim.transmission);
            setFuel(dataTrim.fuel);
            setDriveTrain(dataTrim.driveTrain);
            setBodyType(dataTrim.bodyType);
            setSeats(dataTrim.seats);
            setDoors(dataTrim.doors);

        }
        setBtNext(1)

    };

    const handleEngine = (e) => {
        setEngine(e.target.value)
    };
    const handleTransmission = (e) => {
        setTransmission(e.target.value)
    };
    const handleFuel = (e) => {
        setFuel(e.target.value)
    };
    const handleDriveTrain = (e) => {
        setDriveTrain(e.target.value)
    };
    const handleBodyType = (e) => {
        setBodyType(e.target.value)
    };
    const handleSeats = (e) => {
        setSeats(e.target.value)
    };
    const handleDoors = (e) => {
        setDoors(e.target.value)
    };
    const handlePrice = (e) => {
        if (mileage && price && selectedColor && selectOwner) {
            setBtNext(1)
        }
    };
    const handlePriceExclusive = (e) => {
        // setPriceExclusive(e.target.value)
    };
    const handleSelectColor = e => {
        setSelectedColor(e);
        if (mileage && price && selectedColor && e.value && selectOwner) {
            setBtNext(1)
        }
    };
    const handleSelectOwner = (value) => {
        setSelectOwner(value);
        if (mileage && price && selectedColor) {
            setBtNext(1)
        }
    };


    const handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                thumb: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );
        updateCharacters(files)
        setBtNext(1)
    };
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const uploadImage = async (files) => {
        if (!files) return [];
        let array = [];
        let S3Config = {
            bucketName: process.env.REACT_APP_BucketName,
            region: process.env.REACT_APP_Region,
            dirName: process.env.REACT_APP_DirName,
            accessKeyId: process.env.REACT_APP_S3_AccessKeyId,
            secretAccessKey: process.env.REACT_APP_S3_SecretAccessKey,
            s3Url: process.env.REACT_APP_S3Url,
        };
        const ReactS3Client = new S3(S3Config);
        for (var i = 0; i < files.length; i++) {
            let nameImage = foderImge() + uuid();
            await ReactS3Client.uploadFile(files[i], nameImage)
                .then((res) => {
                    array.push(
                        res.location,
                    );
                })
                .catch((err) => console.error(err));
        }
        return array;
    };

    const toggleTabProgress = async (e, tab) => {
        e.preventDefault();
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 5) {
                setActiveTabProgress(tab);
                if (tab === 1) {
                    setProgressValue(20);
                    if (selectedTrim) {
                        setBtNext(1)
                    } else {
                        setBtNext(0)
                    }
                }
                if (tab === 2) {
                    setProgressValue(40)
                    setSelectNext("ถัดไป");
                    if (selectedColor) {
                        setBtNext(1)
                    } else {
                        setBtNext(0)
                    }
                }
                if (tab === 3) {
                    setProgressValue(60);
                    setSelectNext("ถัดไป");
                    if (detailCar) {
                        setBtNext(1)
                    } else {
                        setBtNext(0)
                    }
                }
                if (tab === 4) {
                    setProgressValue(80);
                    setSelectNext("ถัดไป");
                    if (characters.length > 0) {
                        setBtNext(1)
                    } else {
                        setBtNext(0)
                    }
                }
                if (tab === 5) {
                    setProgressValue(100);
                    setSelectNext("ยืนยัน");
                }
            }
        }
        if (tab === 6) {
            const idDealer = localStorage.getItem("idDealer");
            const imageFiles = await uploadImage(characters)
            const mileage1 = mileage.replace(/,/g, '');
            let priceExclusive1 = 0;
            if (priceExclusive) {
                priceExclusive1 = priceExclusive.replace(/,/g, '');
            }
            const price1 = price.replace(/,/g, '');
            let dataBody = {
                "dealerId": idDealer,
                "content": detailCar,
                "title": selectedYear.value + " " + selectedMake.value + " " + selectedModel.value,
                "year": parseInt(selectedYear.value),
                "make": selectedMake.value,
                "model": selectedModel.value,
                "trimName": selectedTrim.label,
                "fuel": fuel,
                "transmission": transmission,
                "driveTrain": driveTrain,
                "colorIn": null,
                "colorEx": selectedColor.value,
                "carBodyType": bodyType,
                "mileage": parseInt(mileage1),
                "door": doors,
                "seats": seats,
                "engine": engine,
                "useCar": "used",
                "price": parseInt(price1),
                "priceMsrp": null,
                "owners": parseInt(selectOwner.value),
                "priceNew": parseInt(priceExclusive1),
                "thumbnailImage": imageFiles[0],
                "galleryImage": imageFiles
            }
            const carSell = await fetch(process.env.REACT_APP_URL_API + 'cars/sell-cars/',
                {
                    headers: {
                        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    },
                    method: "POST",
                    body: JSON.stringify(dataBody)
                }).then(response => response.json())
                .then(async (res) => {
                    if (res.statusCode === 200) {
                        tog_center();
                    }
                })
                .catch((error) => {
                    history.push('/404');
                });
        }
    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const tog_center = () => {
        setStateModel(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();
    }
    const onSubmitModel = () => {
        history.push('/list-car');
    }


    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateCharacters(items);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="ลงประกาศขายรถ" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div id="progrss-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 1 })}  >
                                                    <span className="step-number">01</span>
                                                    <span className="step-title">รายละเอียดรถ</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 2 })}  >
                                                    <span className="step-number">02</span>
                                                    <span className="step-title">รายละเอียดอื่นๆ</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 3 })} >
                                                    <span className="step-number">03</span>
                                                    <span className="step-title">รายละเอียด</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 4 })}  >
                                                    <span className="step-number">04</span>
                                                    <span className="step-title">เพิ่มรูป</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTabProgress === 5 })} >
                                                    <span className="step-number">05</span>
                                                    <span className="step-title">Confirm Detail</span>
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <div id="bar" className="mt-4">
                                            <Progress color="success" striped animated value={progressValue} />
                                        </div>
                                        <TabContent activeTab={activeTabProgress} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-firstname-input14">ปี *</Label>
                                                                <Select
                                                                    value={selectedYear}
                                                                    onChange={handleSelectYear}
                                                                    options={yearGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-lastname-input15">ยี่ห้อ *</Label>
                                                                <Select
                                                                    value={selectedMake}
                                                                    onChange={handleSelectMake}
                                                                    options={makeGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-phoneno-input16">รุ่น *</Label>
                                                                <Select
                                                                    value={selectedModel}
                                                                    onChange={handleSelectModel}
                                                                    options={modelGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="basicpill-email-input17">รุ่นย่อย *</Label>
                                                                <Select
                                                                    value={selectedTrim}
                                                                    onChange={handleSelectTrim}
                                                                    options={trimGroup}
                                                                    classNamePrefix="select2-selection"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="previous disabled  waves-effect waves-light me-1"
                                                        >
                                                            ย้อนกลับ
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                        >
                                                            ถัดไป
                                                        </Button>
                                                    </ul>
                                                </Form>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <div>
                                                    <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-pancard-input18">ระยะทาง *</Label>
                                                                    <NumberFormat
                                                                        className="form-control"
                                                                        value={mileage}
                                                                        onChange={(e) => setMileage(e.target.value)}
                                                                        thousandSeparator={true}
                                                                        onKeyUp={handleMileage}

                                                                        placeholder="ระบุเลขไมล์"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-vatno-input19">เครื่องยนต์</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={engine}
                                                                        onChange={handleEngine}
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-cstno-input20">เกียร์</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={transmission}
                                                                        onChange={handleTransmission}
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-servicetax-input21">เชื้อเพลิง</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={fuel}
                                                                        onChange={handleFuel}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-companyuin-input22">ขับเคลื่อน</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={driveTrain}
                                                                        onChange={handleDriveTrain}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-declaration-input23">ประเภทรถ</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        value={bodyType}
                                                                        onChange={handleBodyType}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-companyuin-input22">จำนวนที่นั่ง</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={seats}
                                                                        onChange={handleDoors}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-declaration-input23">จำนวนประตู</Label>
                                                                    <Input
                                                                        type="ืnumber"
                                                                        className="form-control"
                                                                        value={doors}
                                                                        onChange={handleSeats}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">ราคาเต็ม</Label>
                                                                    <NumberFormat
                                                                        className="form-control"
                                                                        onChange={(e) => setPrice(e.target.value)}
                                                                        thousandSeparator={true}
                                                                        value={price}
                                                                        onKeyUp={handlePrice}
                                                                        placeholder="ระบุราคารถ"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">ราคาพิเศษ</Label>
                                                                    <NumberFormat

                                                                        className="form-control"
                                                                        value={priceExclusive}
                                                                        onChange={(e) => setPriceExclusive(e.target.value)}
                                                                        thousandSeparator={true}
                                                                        onKeyUp={handlePriceExclusive}
                                                                        placeholder="ระบุราคาพิเศษ (ถ้ามี)"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">สี *</Label>
                                                                    <Select
                                                                        value={selectedColor}
                                                                        onChange={handleSelectColor}
                                                                        options={colorCar}
                                                                        classNamePrefix="select2-selection"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="basicpill-namecard-input24">จำนวนเจ้าของ *</Label>
                                                                    <Select
                                                                        value={selectOwner}
                                                                        onChange={handleSelectOwner}
                                                                        options={owner}
                                                                        classNamePrefix="select2-selection"
                                                                        required
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <Button
                                                                onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                                color="primary"
                                                                className="previous  waves-effect waves-light me-1"
                                                            >
                                                                ย้อนกลับ
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                            >
                                                                ถัดไป
                                                            </Button>
                                                        </ul>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <div>
                                                    <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <CardBody>
                                                                        <CardTitle>เพิ่มรายละเอียดเกี่ยวกับรถ</CardTitle>
                                                                        <CardSubtitle className="mb-3">
                                                                        </CardSubtitle>
                                                                        <Form method="post">
                                                                            <textarea
                                                                                class="form-control"
                                                                                maxlength="7000"
                                                                                type="text"
                                                                                value={detailCar}
                                                                                onChange={handleDetailCar}
                                                                            />
                                                                        </Form>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                            <Button
                                                                onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                                color="primary"
                                                                className="previous  waves-effect waves-light me-1"
                                                            >
                                                                ย้อนกลับ
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                            >
                                                                ถัดไป
                                                            </Button>
                                                        </ul>

                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={4}>

                                                <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                    <Dropzone
                                                        onDrop={acceptedFiles => {
                                                            if (acceptedFiles.length > 20) {
                                                                alert('file limit 20')
                                                            } else {
                                                                handleAcceptedFiles(acceptedFiles)
                                                            }
                                                        }}
                                                    >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="file-previews"
                                                >
                                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                                        <Droppable droppableId="characters">
                                                            {(provided) => (

                                                                <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {characters.map(({ path, thumb, name }, index) => {
                                                                        return (
                                                                            <Draggable key={path} draggableId={path} index={index}>
                                                                                {(provided) => (
                                                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                        <div className="characters-thumb">
                                                                                            <img className="image-sellcar" src={thumb} alt={`${name} Thumb`} />
                                                                                        </div>
                                                                                        <p>
                                                                                            {name}
                                                                                        </p>
                                                                                    </li>
                                                                                )}
                                                                            </Draggable>

                                                                        );
                                                                    })}
                                                                    {provided.placeholder}

                                                                </ul>

                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>

                                                </div>
                                                <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                    <Button
                                                        onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                        color="primary"
                                                        className="previous  waves-effect waves-light me-1"
                                                    >
                                                        ย้อนกลับ
                                                    </Button>

                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className={btNext === 0 ? "waves-effect waves-light me-1 btNext-sell-car disabled" : "waves-effect waves-light me-1 btNext-sell-car"}
                                                    >
                                                        ถัดไป
                                                    </Button>
                                                </ul>
                                            </Form>
                                        </TabPane>
                                        <TabPane tabId={5}>
                                            <Form onSubmit={(e) => toggleTabProgress(e, (activeTabProgress + 1))}>
                                                <div className="row justify-content-center">
                                                    <Col lg="6">
                                                        <div className="text-center">
                                                            <div className="mb-4">
                                                                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                            </div>
                                                            <div>
                                                                <h5>Confirm Detail</h5>
                                                                <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                                <ul className="pager wizard twitter-bs-wizard-pager-link">

                                                    <Button
                                                        // type="submit"
                                                        // (e) => toggleTabProgress(e, (activeTabProgress + 1))
                                                        onClick={(e) => toggleTabProgress(e, (activeTabProgress - 1))}
                                                        color="primary"
                                                        className="previous  waves-effect waves-light me-1"
                                                    >
                                                        ย้อนกลับ
                                                    </Button>

                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className=" waves-effect waves-light me-1 btNext-sell-car"
                                                    >
                                                        ยืนยัน
                                                    </Button>
                                                </ul>
                                            </Form>
                                        </TabPane>
                                    </TabContent>
                                    <Modal
                                        isOpen={stateModel.modal_center}
                                        toggle={tog_center}
                                        centered={true}
                                        className="modelCenter"

                                    >
                                        <ModalBody className="bodyModelCenet">
                                            <h1>เพิ่มรถสำเร็จ</h1>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="secondary"
                                                outline
                                                type="submit"
                                                onClick={onSubmitModel}
                                                className="waves-effect">
                                                ปิด
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
        </React.Fragment >
    );

}

export default memo(FormSellCar);
