import React, { Component, useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Col, Card, CardBody, Modal, Container, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { Auth } from "aws-amplify";

//i18n
import { withNamespaces } from "react-i18next";

// users
import { useHistory } from 'react-router-dom';

import avatar2 from '../../../assets/images/users/avatar-2.jpg';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
// Modal.setAppElement('#yourAppElement');

function ProfileMenu(props) {
    const username = localStorage.getItem("userName");
    useEffect(() => {

    }, []);
    const history = useHistory();

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [code, setCode] = useState("");
    const [stateModelFoGotPassword, setStateModelFoGotPassword] = useState({});

    function openModal() {
        setIsOpen(true);
    }
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [state, setState] = useState({ menu: false });
    const [stateModel, setStateModel] = useState({});
    const [newPassword, setNewPassword] = useState("");
    const [emailResetPasswordConfirm, setEmailResetPasswordConfirm] = useState("");


    const toggle = () => {
        setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    const submitResetPassword = (event) => {
        event.preventDefault();
        const email = localStorage.getItem("emailDealer");
        Auth.forgotPassword(email)
            .then((res) => {
                setStateModelFoGotPassword(prevState => ({
                    modal_center: !prevState.modal_center
                }));
                removeBodyCss();
            })
            .catch((err) => {
                console.log(err)
            });

    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const tog_forgot_password = (e) => {
        e.preventDefault();
        setStateModelFoGotPassword(prevState => ({
            modal_center: !prevState.modal_center
        }));
        removeBodyCss();

    }
    const onSubmitResetPasswordConfirm = (e) => {
        e.preventDefault();
        Auth.forgotPasswordSubmit(emailResetPasswordConfirm, code, newPassword)
            .then((res) => {
                setStateModelFoGotPassword(prevState => ({
                    modal_center: !prevState.modal_center
                }));
            })
            .catch((err) => {
                console.log(err);
                Auth.signOut()
                    .then(() => {
                        localStorage.setItem("idDealer", "")
                        localStorage.setItem("emailDealer", "")
                        localStorage.setItem("userName", "");
                        window.location.href = '/dealerplatform/login'
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
    };
    const submitProfile= () =>{
        history.push('/profile');
    }
    const submitLogout= () =>{
        history.push('/logout');
    }


    return (
        <React.Fragment>
            <Dropdown isOpen={state.menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                    {/* <img className="rounded-circle header-profile-user me-1" src={avatar2} alt="Header Avatar" /> */}
                    <i class='me-1 fas fa-user-alt'></i>
                    <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
                    <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    
                    <DropdownItem onClick={submitProfile}><i className="ri-user-line align-middle me-1"></i>โปรไฟล์</DropdownItem>
                    <DropdownItem onClick={submitResetPassword}><i className="ri-wallet-2-line align-middle me-1"></i> เปลี่ยนรหัสผ่าน</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" onClick={submitLogout}><i className="ri-shut-down-line align-middle me-1 text-danger"></i> ออกจากระบบ</DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <Modal
                isOpen={stateModelFoGotPassword.modal_center}
                toggle={tog_forgot_password}
                centered={true}
            >
                <ModalHeader toggle={() => setStateModelFoGotPassword({ modal_static: false })} >
                    คุณลืมรหัสผ่าน?
                </ModalHeader>

                <form onSubmit={onSubmitResetPasswordConfirm}>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="register-code-2">

                                รหัส OTP จะถูกส่งไปที่อีเมลของคุณ
                                ป้อนที่อยู่อีเมล รหัส OTP และรหัสผ่านใหม่ด้านล่างเพื่อรีเซ็ตรหัสผ่านของคุณ
                            </label>
                            <div className="model-fogotpassword">
                                <input name="username"
                                    // value={username}
                                    type="email"
                                    className="form-control"
                                    required
                                    onChange={(e) => setEmailResetPasswordConfirm(e.target.value)}
                                    placeholder="ป้อนอีเมลลื" />
                            </div>
                            <div className="model-fogotpassword">
                                <input
                                    type="text"
                                    onChange={(e) => setCode(e.target.value)}
                                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    className="form-control" id="register-code-2"
                                    placeholder="ป้อนรหัส OTP" autoComplete="off"
                                    required
                                    
                                />
                            </div>
                            <div className="model-fogotpassword">
                                <Input
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    type="password"
                                    className="form-control"
                                    required
                                    placeholder="ป้อนรหัสผ่านใหม่ของคุณ"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={tog_forgot_password}
                            color="danger"
                            outline
                            className="waves-effect waves-light me-1"
                        >
                            ยกเลิก
                        </Button>

                        <Button
                            color="secondary"
                            outline
                            type="submit"
                            // onClick={onSubmitResetPasswordConfirm}
                            className="waves-effect">
                            ยินยัน
                        </Button>

                    </ModalFooter>

                </form>


            </Modal>
        </React.Fragment>

    );

}

export default withNamespaces()(ProfileMenu);
