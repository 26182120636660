import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CountUp from "react-countup";

import { httpClient } from "../../../util/api";

function PriceGuid2(props) {
  const ownerCalling = localStorage.getItem("emailDealer") || "n/a";

  const [loading, setLoading] = useState("");

  const [suggestedRetail, setSuggestedRetail] = useState(null);
  const [suggestedRetailMax, setSuggestedRetailMax] = useState(null);
  const [suggestedRetailMin, setSuggestedRetailMin] = useState(null);
  const [auctionPrice, setAuctionPrice] = useState(null);
  const [auctionPriceMax, setAuctionPriceMax] = useState(null);
  const [auctionPriceMin, setAuctionPriceMin] = useState(null);
  const [msrp, setMsrp] = useState(null);
  const [privateParty, setPrivateParty] = useState(null);
  const [privatePartyMax, setPrivatePartyMax] = useState(null);
  const [privatePartyMin, setPrivatePartyMin] = useState(null);
  const [tradeIn, setTradeIn] = useState(null);
  const [tradeInMax, setTradeInMax] = useState(null);
  const [tradeInMin, setTradeInMin] = useState(null);

  const [found, setFound] = useState("");

  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const queryParams = new URLSearchParams(window.location.search);
  const year = queryParams.get("year");
  const make = queryParams.get("make");
  const model = queryParams.get("model");
  const trim = queryParams.get("trim");
  const color = queryParams.get("color");
  const owner = queryParams.get("owner");
  const mileage = queryParams.get("mileage");
  const condition = queryParams.get("condition");
  const trimId = queryParams.get("trimId");
  const engine = queryParams.get("engine");
  const msrpParam = queryParams.get("msrpParam");

  const regDate = year + "/01/01";
  var todayOver =
    today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();

  var nf = new Intl.NumberFormat();
  var conditionName = "";
  if (condition === "Poor") {
    conditionName = "พอใช้";
  } else if (condition === "Average") {
    conditionName = "ปานกลาง";
  } else if (condition === "Good") {
    conditionName = "ดี";
  } else if (condition === "Excellent") {
    conditionName = "ดีเยี่ยม";
  }
  useEffect(() => {
    setLoading(":Loading data");
    const req = {
      make: make,
      model: model ? model.replaceAll("_", " ") : "",
      subModel: trim ? trim.replaceAll("_", " ") : "",
      engineCapacity: parseFloat(engine),
      manufactureYear: parseInt(year),
      milage: parseInt(mileage),
      owner: ownerCalling,
      condition: condition,
    };

    console.log("post");
    httpClient
      .post("/prices/buy-in-auction", req)
      .then(({ data }) => {
        console.log(data);
        if (data.data && data.data.buyIn) {
          setFound(": พบข้อมูล");

          let entries = {};

          if (data.data.buyIn) {
            let auctionPrice = data.data.buyIn;

            // 'Bad' : -0.1,
            // 'Poor' : -0.05,
            // 'Average' : -0.01, 100000 - 100000 * 0.01
            // 'Good' : 0.01,
            // 'Excellent' : 0.03
            if (condition === "Poor") {
              auctionPrice = data.data.buyIn - data.data.buyIn * 0.05;
            } else if (condition === "Average") {
              auctionPrice = data.data.buyIn - data.data.buyIn * 0.01;
            } else if (condition === "Good") {
              auctionPrice = data.data.buyIn + data.data.buyIn * 0.01;
            } else if (condition === "Excellent") {
              auctionPrice = data.data.buyIn + data.data.buyIn * 0.03;
            } else if (condition === "Bad") {
              auctionPrice = data.data.buyIn - data.data.buyIn * 0.1;
            }

            console.log(auctionPrice);

            const MAX = 1.1;
            const MIN = 0.9;
            entries = [
              {
                SuggestedRetail: auctionPrice * 1.16,
                SuggestedRetailMax: auctionPrice * 1.16 * MAX,
                SuggestedRetailMin: auctionPrice * 1.16 * MIN,
                auctionPrice: auctionPrice,
                auctionPriceMax: auctionPrice * MAX,
                auctionPriceMin: auctionPrice * MIN,
                msrp: msrpParam,
                privateParty: auctionPrice * 1.01,
                privatePartyMax: auctionPrice * 1.01 * MAX,
                privatePartyMin: auctionPrice * 1.01 * MIN,
                tradeIn: auctionPrice * 0.94,
                tradeInMax: auctionPrice * 0.94 * MAX,
                tradeInMin: auctionPrice * 0.94 * MIN,
                trimId: "2019811538020190722",
              },
            ];
            if (data.data.pricing) {
              entries["SuggestedRetail"] = data.data.pricing;
              entries["SuggestedRetailMax"] = data.data.pricing * MAX;
              entries["SuggestedRetailMin"] = data.data.pricing * MIN;
            }
          } else {
            entries = [
              {
                SuggestedRetail: 0,
                SuggestedRetailMax: 0,
                SuggestedRetailMin: 0,
                auctionPrice: 0,
                auctionPriceMax: 0,
                auctionPriceMin: 0,
                msrp: msrpParam,
                privateParty: 0,
                privatePartyMax: 0,
                privatePartyMin: 0,
                tradeIn: 0,
                tradeInMax: 0,
                tradeInMin: 0,
                trimId: "2019811538020190722",
              },
            ];
          }

          //   retail_price = auction_price * 1.16;
          //   private_price = auction_price * 1.01;
          //   trading_price = auction_price * 0.94;

          //   max_price = mid_price * 1.1;
          //   min_price = mid_price * 0.9;

          setSuggestedRetail(entries[0].SuggestedRetail);
          setSuggestedRetailMax(entries[0].SuggestedRetailMax);
          setSuggestedRetailMin(entries[0].SuggestedRetailMin);
          setAuctionPrice(entries[0].auctionPrice);
          setAuctionPriceMax(entries[0].auctionPriceMax);
          setAuctionPriceMin(entries[0].auctionPriceMin);
          setMsrp(entries[0].msrp);
          setPrivateParty(entries[0].privateParty);
          setPrivatePartyMax(entries[0].privatePartyMax);
          setPrivatePartyMin(entries[0].privatePartyMin);
          setTradeIn(entries[0].tradeIn);
          setTradeInMax(entries[0].tradeInMax);
          setTradeInMin(entries[0].tradeInMin);
        } else {
          setLoading(undefined);
          setFound(": ไม่พบข้อมูล");

          setSuggestedRetail(0);
          setSuggestedRetailMax(0);
          setSuggestedRetailMin(0);
          setAuctionPrice(0);
          setAuctionPriceMax(0);
          setAuctionPriceMin(0);
          setMsrp(0);
          setPrivateParty(0);
          setPrivatePartyMax(0);
          setPrivatePartyMin(0);
          setTradeIn(0);
          setTradeInMax(0);
          setTradeInMin(0);
        }
        setLoading("");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(undefined);
        setFound(": ไม่พบข้อมูล");
      });

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-API-KEY": "a3626787-2447-426d-a532-94dc8e345779",
    //   },
    //   body: JSON.stringify(bodtRequest),
    // };
    // const param = `make=${make}&model=${model}&subModel=${trim}&engineCapacity=${engine}&mileage=${mileage}&regDate=${regDate}&hanoverDate=${todayOver}&manufacturingYear=${year}`;

    // fetch(
    //   "https://ae1204831b94d4d7db30f69601e75750-158951698.ap-southeast-1.elb.amazonaws.com/api/v1/prices/buy-in-auction",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     if (data.statusCode === 200 && data.data && data.data.buyIn) {
    //       setFound("");

    //       let entries = {};

    //       if (data.data.buyIn) {
    //         let auctionPrice = data.data.buyIn;

    //         // 'Bad' : -0.1,
    //         // 'Poor' : -0.05,
    //         // 'Average' : -0.01, 100000 - 100000 * 0.01
    //         // 'Good' : 0.01,
    //         // 'Excellent' : 0.03
    //         if (condition === "Poor") {
    //           auctionPrice = data.data.buyIn - data.data.buyIn * 0.05;
    //         } else if (condition === "Average") {
    //           auctionPrice = data.data.buyIn - data.data.buyIn * 0.01;
    //         } else if (condition === "Good") {
    //           auctionPrice = data.data.buyIn + data.data.buyIn * 0.01;
    //         } else if (condition === "Excellent") {
    //           auctionPrice = data.data.buyIn + data.data.buyIn * 0.03;
    //         } else if (condition === "Bad") {
    //           auctionPrice = data.data.buyIn - data.data.buyIn * 0.1;
    //         }

    //         console.log(auctionPrice);

    //         const MAX = 1.1;
    //         const MIN = 0.9;
    //         entries = [
    //           {
    //             SuggestedRetail: auctionPrice * 1.16,
    //             SuggestedRetailMax: auctionPrice * 1.16 * MIN,
    //             SuggestedRetailMin: auctionPrice * 1.16 * MAX,
    //             auctionPrice: auctionPrice,
    //             auctionPriceMax: auctionPrice * MAX,
    //             auctionPriceMin: auctionPrice * MIN,
    //             msrp: msrpParam,
    //             privateParty: auctionPrice * 1.01,
    //             privatePartyMax: auctionPrice * 1.01 * MAX,
    //             privatePartyMin: auctionPrice * 1.01 * MIN,
    //             tradeIn: auctionPrice * 0.94,
    //             tradeInMax: auctionPrice * 0.94 * MAX,
    //             tradeInMin: auctionPrice * 0.94 * MIN,
    //             trimId: "2019811538020190722",
    //           },
    //         ];
    //       } else {
    //         entries = [
    //           {
    //             SuggestedRetail: 0,
    //             SuggestedRetailMax: 0,
    //             SuggestedRetailMin: 0,
    //             auctionPrice: 0,
    //             auctionPriceMax: 0,
    //             auctionPriceMin: 0,
    //             msrp: msrpParam,
    //             privateParty: 0,
    //             privatePartyMax: 0,
    //             privatePartyMin: 0,
    //             tradeIn: 0,
    //             tradeInMax: 0,
    //             tradeInMin: 0,
    //             trimId: "2019811538020190722",
    //           },
    //         ];
    //       }

    //       //   retail_price = auction_price * 1.16;
    //       //   private_price = auction_price * 1.01;
    //       //   trading_price = auction_price * 0.94;

    //       //   max_price = mid_price * 1.1;
    //       //   min_price = mid_price * 0.9;

    //       setSuggestedRetail(entries[0].SuggestedRetail);
    //       setSuggestedRetailMax(entries[0].SuggestedRetailMax);
    //       setSuggestedRetailMin(entries[0].SuggestedRetailMin);
    //       setAuctionPrice(entries[0].auctionPrice);
    //       setAuctionPriceMax(entries[0].auctionPriceMax);
    //       setAuctionPriceMin(entries[0].auctionPriceMin);
    //       setMsrp(entries[0].msrp);
    //       setPrivateParty(entries[0].privateParty);
    //       setPrivatePartyMax(entries[0].privatePartyMax);
    //       setPrivatePartyMin(entries[0].privatePartyMin);
    //       setTradeIn(entries[0].tradeIn);
    //       setTradeInMax(entries[0].tradeInMax);
    //       setTradeInMin(entries[0].tradeInMin);
    //     } else {
    //       setLoading(": No data");
    //       setFound(": ไม่พบข้อมูล");

    //       setSuggestedRetail(0);
    //       setSuggestedRetailMax(0);
    //       setSuggestedRetailMin(0);
    //       setAuctionPrice(0);
    //       setAuctionPriceMax(0);
    //       setAuctionPriceMin(0);
    //       setMsrp(0);
    //       setPrivateParty(0);
    //       setPrivatePartyMax(0);
    //       setPrivatePartyMin(0);
    //       setTradeIn(0);
    //       setTradeInMax(0);
    //       setTradeInMin(0);
    //     }
    //     setLoading("");
    //   });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Jquery Knob" breadcrumbItems={this.state.breadcrumbItems} /> */}

          <Row>
            <Col xs="12">
              <div class="dl-login-head mb-3 ">
                <h1 class="font-32px ">
                  รถที่คุณเลือก : {year} {make} {model} {trim} {found}{" "}
                  {loading ? "Loading data" : ""}
                </h1>
                <div class="date_tab ">
                  <div class="line-height">
                    <div>
                      <span class="head_tab_title">เลขไมล์</span>
                      <span separator=",">{nf.format(mileage)}</span>
                    </div>
                    <div>
                      <span class="head_tab_title">สภาพรถ</span>
                      <span separator=",">{conditionName}</span>
                    </div>
                    <div>
                      <span class="head_tab_title">วันที่</span>
                      <span separator=",">{date}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row layout-detail ">
                <div class="col-md-3 ">
                  <div class="layout-item">
                    <div class="dl-msrp-price">
                      <div class="msrp-price-head ">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/newprice.png" />
                        </div>
                        <div>
                          <h4 class="mt10-480 font-dealer">ราคารถใหม่</h4>
                          <h5 class="font-dealer">New Car</h5>
                        </div>
                      </div>
                      <div class="msrp-price-body text-center ">
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={msrp}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div>
                        <img
                          class="newcar-img-content"
                          src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/newcar-bg.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/tradein.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white ">
                          ราคาซื้อเข้า
                        </h4>
                        <h5 class="font-dealer text-color-white ">
                          Trade In | C2B
                        </h5>
                      </div>
                      <div class="msrp-price-body ">
                        <span class="font-dealer">HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeInMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeIn}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeInMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="m-2">
                        <button class="dl-btn-green font-dealer">
                          ระยะเวลา 1-7 วัน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/private.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาซื้อขายเอง
                        </h4>
                        <h5 class="font-dealer text-color-white">
                          Private to Private
                        </h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privatePartyMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privateParty}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer ">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privatePartyMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="m-2 ">
                        <button class="dl-btn-border font-dealer">
                          ระยะเวลา 30-60 วัน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/b2c.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาขาย
                        </h4>
                        <h5 class="font-dealer text-color-white">
                          Dealer Retail | B2C
                        </h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetailMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetail}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetailMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/auction.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาประมูล
                        </h4>
                        <h5 class="font-dealer text-color-white">Auction</h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPriceMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPrice}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPriceMin}
                              duration={3.5}
                              separator=","
                            />{" "}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PriceGuid2;
