import React, { Component, useEffect, useState } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
function SidebarContent(props) {
  const [display, setDisplay] = useState(0);

  const dealerType = localStorage.getItem("dealerType") || null;

  useEffect(() => {
    initMenu();
    if (dealerType === "dealer-vip") {
      setDisplay(1);
    }
  }, []);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {}
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu</li>

          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="ri-dashboard-line"></i>
              <span className="badge rounded-pill bg-success float-end"></span>
              <span className="ms-1">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ri-store-2-line"></i>
              <span className="ms-1">ประกาศขายรถ</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/form-sell-car">เพิ่มรายการรถ</Link>
              </li>
              <li>
                <Link to="/list-car">รายการรถ</Link>
              </li>
            </ul>
          </li>

          {display === 1 && (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-eraser-fill"></i>
                <span className="ms-1">บริการดาต้าเซอวิส</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/carprice">ดาต้าเซอวิส</Link>
                </li>
              </ul>
              {/* <ul className="sub-menu">
                            <li><Link to="/carprice-v2">ดาต้าเซอวิส-new</Link></li>
                        </ul> */}
            </li>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
}

// const mapStatetoProps = state => {
//     return { ...state.Layout };
// };

// export default withRouter(connect(mapStatetoProps, {
//     changeLayout,
//     changeSidebarTheme,
//     changeSidebarType,
//     changeLayoutWidth,
//     changePreloader
// })(withNamespaces()(SidebarContent)));

export default SidebarContent;
