import React , {memo} from "react";
import { Route, Redirect } from "react-router-dom";
// import { Auth } from "aws-amplify";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
	<Route
		exct path="/"
		{...rest}
		render={props => {
			const url = window.location.pathname.split('/').pop();
			if (!isAuthProtected && !localStorage.getItem("authUser")) {
				window.location.href = '/dealerplatform/login'
			}
			return (
				<Layout>
					<Component {...props} />
				</Layout>
			);

		}}
	/>
);

export default memo(AppRoute);

