import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CountUp from "react-countup";
import { httpClient } from "../../../util/api";

function PriceGuid(props) {
  const ownerCalling = localStorage.getItem("emailDealer") || "n/a";
  const [suggestedRetail, setSuggestedRetail] = useState(null);
  const [suggestedRetailMax, setSuggestedRetailMax] = useState(null);
  const [suggestedRetailMin, setSuggestedRetailMin] = useState(null);
  const [auctionPrice, setAuctionPrice] = useState(null);
  const [auctionPriceMax, setAuctionPriceMax] = useState(null);
  const [auctionPriceMin, setAuctionPriceMin] = useState(null);
  const [msrp, setMsrp] = useState(null);
  const [privateParty, setPrivateParty] = useState(null);
  const [privatePartyMax, setPrivatePartyMax] = useState(null);
  const [privatePartyMin, setPrivatePartyMin] = useState(null);
  const [tradeIn, setTradeIn] = useState(null);
  const [tradeInMax, setTradeInMax] = useState(null);
  const [tradeInMin, setTradeInMin] = useState(null);

  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const queryParams = new URLSearchParams(window.location.search);
  const year = queryParams.get("year");
  const make = queryParams.get("make");
  const model = queryParams.get("model");
  const trim = queryParams.get("trim");
  const color = queryParams.get("color");
  const owner = queryParams.get("owner");
  const mileage = queryParams.get("mileage");
  const condition = queryParams.get("condition");
  const trimId = queryParams.get("trimId");
  var nf = new Intl.NumberFormat();
  var conditionName = "";
  if (condition === "Poor") {
    conditionName = "พอใช้";
  } else if (condition === "Average") {
    conditionName = "ปานกลาง";
  } else if (condition === "Good") {
    conditionName = "ดี";
  } else if (condition === "Excellent") {
    conditionName = "ดีเยี่ยม";
  }
  useEffect(() => {
    var myBody = {
      Transactions: [
        {
          trimId: trimId,
          mileage: parseInt(mileage),
          owner: owner,
          condition: condition,
          color: color,
        },
      ],
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },

      body: JSON.stringify(myBody),
    };
    fetch(
      "https://xai634j4wa.execute-api.ap-southeast-1.amazonaws.com/production/predict",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuggestedRetail(data.entries[0].SuggestedRetail);
          setSuggestedRetailMax(data.entries[0].SuggestedRetailMax);
          setSuggestedRetailMin(data.entries[0].SuggestedRetailMin);
          setAuctionPrice(data.entries[0].auctionPrice);
          setAuctionPriceMax(data.entries[0].auctionPriceMax);
          setAuctionPriceMin(data.entries[0].auctionPriceMin);
          setMsrp(data.entries[0].msrp);
          setPrivateParty(data.entries[0].privateParty);
          setPrivatePartyMax(data.entries[0].privatePartyMax);
          setPrivatePartyMin(data.entries[0].privatePartyMin);
          setTradeIn(data.entries[0].tradeIn);
          setTradeInMax(data.entries[0].tradeInMax);
          setTradeInMin(data.entries[0].tradeInMin);
        }
      });

    try {
      const req = {
        make: make,
        model: model ? model.replaceAll("_", " ") : "",
        subModel: trim ? trim.replaceAll("_", " ") : "",
        engineCapacity: 0,
        manufactureYear: parseInt(year),
        milage: parseInt(mileage),
        owner: ownerCalling,
      };

      console.log("post");
      httpClient
        .post("/prices/buy-in-his", req)
        .then(({ data }) => {})
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {}
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Jquery Knob" breadcrumbItems={this.state.breadcrumbItems} /> */}

          <Row>
            <Col xs="12">
              <div class="dl-login-head mb-3 ">
                <h1 class="font-32px ">
                  รถที่คุณเลือก : {year} {make} {model} {trim}
                </h1>
                <div class="date_tab ">
                  <div class="line-height">
                    <div>
                      <span class="head_tab_title">เลขไมล์</span>
                      <span separator=",">{nf.format(mileage)}</span>
                    </div>
                    <div>
                      <span class="head_tab_title">สภาพรถ</span>
                      <span separator=",">{conditionName}</span>
                    </div>
                    <div>
                      <span class="head_tab_title">วันที่</span>
                      <span separator=",">{date}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row layout-detail ">
                <div class="col-md-3 ">
                  <div class="layout-item">
                    <div class="dl-msrp-price">
                      <div class="msrp-price-head ">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/newprice.png" />
                        </div>
                        <div>
                          <h4 class="mt10-480 font-dealer">ราคารถใหม่</h4>
                          <h5 class="font-dealer">New Car</h5>
                        </div>
                      </div>
                      <div class="msrp-price-body text-center ">
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={msrp}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div>
                        <img
                          class="newcar-img-content"
                          src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/newcar-bg.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/tradein.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white ">
                          ราคาซื้อเข้า
                        </h4>
                        <h5 class="font-dealer text-color-white ">
                          Trade In | C2B
                        </h5>
                      </div>
                      <div class="msrp-price-body ">
                        <span class="font-dealer">HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeInMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeIn}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={tradeInMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="m-2">
                        <button class="dl-btn-green font-dealer">
                          ระยะเวลา 1-7 วัน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/private.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาซื้อขายเอง
                        </h4>
                        <h5 class="font-dealer text-color-white">
                          Private to Private
                        </h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privatePartyMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privateParty}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer ">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={privatePartyMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="m-2 ">
                        <button class="dl-btn-border font-dealer">
                          ระยะเวลา 30-60 วัน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/b2c.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาขาย
                        </h4>
                        <h5 class="font-dealer text-color-white">
                          Dealer Retail | B2C
                        </h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetailMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetail}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={suggestedRetailMin}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="layout-item">
                    <div class="dl-msrp-price-all">
                      <div class="msrp-price-head">
                        <div class="price-icon">
                          <img src="https://s3-motto-content.s3.ap-southeast-1.amazonaws.com/icon-priceguide/auction.png" />
                        </div>
                        <h4 class="mt10-480 font-dealer text-color-white">
                          ราคาประมูล
                        </h4>
                        <h5 class="font-dealer text-color-white">Auction</h5>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>HI</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPriceMax}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="mid-price font-dealer">
                        <span>MID</span>
                        <h2 class="text-color-white font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPrice}
                              duration={3.5}
                              separator=","
                            />
                          </span>
                        </h2>
                      </div>
                      <div class="msrp-price-body font-dealer">
                        <span>LOW</span>
                        <h2 class="font-dealer">
                          ฿
                          <span class="count">
                            <CountUp
                              start={0}
                              end={auctionPriceMin}
                              duration={3.5}
                              separator=","
                            />{" "}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PriceGuid;
